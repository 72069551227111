import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { DataPlanType } from '../../types/data-plan/data-plan.type';
import { DataPlanTypeType } from '../../types/data-plan/data-plan-type/data-plan-type.type';
import { createSlice } from '@reduxjs/toolkit';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import snackbarUtils from '../../utils/SnackbarUtils';
import {
  deleteDataPlanDetails,
  deleteDataPlanTypeDetails,
  getDataPlanDetails,
  getDataPlansList,
  getDataPlanTypeDetails,
  getDataPlanTypesList,
  patchDataPlanDetails,
  patchDataPlanTypeDetails,
  postDataPlanDetails,
  postDataPlanTypeDetails,
} from './dataPlans.action';

export interface DataPlansState {
  dataPlansList: null | PaginationType<DataPlanType>;
  dataPlansListFetchStatus: FETCH_STATUS;
  dataPlansRouteParams: RouteParamsType;
  dataPlanDetails: null | DataPlanType;
  dataPlanDetailsFetchStatus: FETCH_STATUS;
  dataPlanDetailsPostStatus: FETCH_STATUS;
  dataPlanDetailsPatchStatus: FETCH_STATUS;
  dataPlanDetailsDeleteStatus: FETCH_STATUS;

  dataPlanTypesList: null | PaginationType<DataPlanTypeType>;
  dataPlanTypesListFetchStatus: FETCH_STATUS;
  dataPlanTypesRouteParams: RouteParamsType;
  dataPlanTypeDetails: null | DataPlanTypeType;
  dataPlanTypeDetailsFetchStatus: FETCH_STATUS;
  dataPlanTypeDetailsPostStatus: FETCH_STATUS;
  dataPlanTypeDetailsPatchStatus: FETCH_STATUS;
  dataPlanTypeDetailsDeleteStatus: FETCH_STATUS;
}

export const initialDataPlansRouteParams = {
  page: 1,
  limit: 10,
  order: '-data_plan.id',
  data_plan_type_id: '',
  q: '',
};

export const initialDataPlanTypesRouteParams = {
  page: 1,
  limit: 10,
  order: '-data_plan_type.id',
  q: '',
};

const INIT_STATE: DataPlansState = {
  dataPlansList: null,
  dataPlansListFetchStatus: FETCH_STATUS.NULL,
  dataPlansRouteParams: initialDataPlansRouteParams,
  dataPlanDetails: null,
  dataPlanDetailsFetchStatus: FETCH_STATUS.NULL,
  dataPlanDetailsPostStatus: FETCH_STATUS.NULL,
  dataPlanDetailsPatchStatus: FETCH_STATUS.NULL,
  dataPlanDetailsDeleteStatus: FETCH_STATUS.NULL,

  dataPlanTypesList: null,
  dataPlanTypesListFetchStatus: FETCH_STATUS.NULL,
  dataPlanTypesRouteParams: initialDataPlanTypesRouteParams,
  dataPlanTypeDetails: null,
  dataPlanTypeDetailsFetchStatus: FETCH_STATUS.NULL,
  dataPlanTypeDetailsPostStatus: FETCH_STATUS.NULL,
  dataPlanTypeDetailsPatchStatus: FETCH_STATUS.NULL,
  dataPlanTypeDetailsDeleteStatus: FETCH_STATUS.NULL,
};

const dataPlansSlice = createSlice({
  name: 'data-plans',
  initialState: INIT_STATE,
  reducers: {
    setDataPlansList: (state, action) => {
      state.dataPlansList = action.payload;
    },
    setDataPlansListFetchStatus: (state, action) => {
      state.dataPlansListFetchStatus = action.payload;
    },
    setDataPlanDetails: (state, action) => {
      state.dataPlanDetails = action.payload;
    },
    setDataPlanDetailsFetchStatus: (state, action) => {
      state.dataPlanDetailsFetchStatus = action.payload;
    },
    setDataPlanDetailsPostStatus: (state, action) => {
      state.dataPlanDetailsPostStatus = action.payload;
    },
    setDataPlanDetailsPatchStatus: (state, action) => {
      state.dataPlanDetailsPatchStatus = action.payload;
    },
    setDataPlanDetailsDeleteStatus: (state, action) => {
      state.dataPlanDetailsDeleteStatus = action.payload;
    },

    setDataPlanTypesList: (state, action) => {
      state.dataPlanTypesList = action.payload;
    },
    setDataPlanTypesListFetchStatus: (state, action) => {
      state.dataPlanTypesListFetchStatus = action.payload;
    },
    setDataPlanTypeDetails: (state, action) => {
      state.dataPlanTypeDetails = action.payload;
    },
    setDataPlanTypeDetailsFetchStatus: (state, action) => {
      state.dataPlanTypeDetailsFetchStatus = action.payload;
    },
    setDataPlanTypeDetailsPostStatus: (state, action) => {
      state.dataPlanTypeDetailsPostStatus = action.payload;
    },
    setDataPlanTypeDetailsPatchStatus: (state, action) => {
      state.dataPlanTypeDetailsPatchStatus = action.payload;
    },
    setDataPlanTypeDetailsDeleteStatus: (state, action) => {
      state.dataPlanTypeDetailsDeleteStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'dataPlans') {
        state.dataPlansRouteParams = { ...state.dataPlansRouteParams, ...payload.data };
      }

      if (payload.reducer === 'dataPlanTypes') {
        state.dataPlanTypesRouteParams = { ...state.dataPlanTypesRouteParams, ...payload.data };
      }
    });

    // CREATE DATA PLANS DETAILS
    builder.addCase(postDataPlanDetails.pending, state => {
      state.dataPlanDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postDataPlanDetails.rejected, (state, action) => {
      state.dataPlanDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postDataPlanDetails.fulfilled, state => {
      state.dataPlanDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Data plan created successfully!'));
    });

    // GET DATA PLANS LIST
    builder.addCase(getDataPlansList.pending, state => {
      state.dataPlansListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getDataPlansList.rejected, (state, action) => {
      state.dataPlansListFetchStatus = FETCH_STATUS.REJECTED;
      state.dataPlansList = null;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(getDataPlansList.fulfilled, (state, action) => {
      state.dataPlansListFetchStatus = FETCH_STATUS.FULFILLED;
      state.dataPlansList = action.payload;
    });

    // GET DATA PLANS DETAILS
    builder.addCase(getDataPlanDetails.pending, state => {
      state.dataPlanDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getDataPlanDetails.rejected, (state, action) => {
      state.dataPlanDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.dataPlanDetails = null;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(getDataPlanDetails.fulfilled, (state, action) => {
      state.dataPlanDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.dataPlanDetails = action.payload;
    });

    // PATCH DATA PLANS DETAILS
    builder.addCase(patchDataPlanDetails.pending, state => {
      state.dataPlanDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchDataPlanDetails.rejected, (state, action) => {
      state.dataPlanDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(patchDataPlanDetails.fulfilled, state => {
      state.dataPlanDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Data plan updated successfully!'));
    });

    // DELETE DATA PLANS DETAILS
    builder.addCase(deleteDataPlanDetails.pending, state => {
      state.dataPlanDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteDataPlanDetails.rejected, (state, action) => {
      state.dataPlanDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(deleteDataPlanDetails.fulfilled, state => {
      state.dataPlanDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Data plan deleted successfully!'));
    });

    // CREATE DATA PLANS TYPES DETAILS
    builder.addCase(postDataPlanTypeDetails.pending, state => {
      state.dataPlanTypeDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postDataPlanTypeDetails.rejected, (state, action) => {
      state.dataPlanTypeDetailsPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postDataPlanTypeDetails.fulfilled, state => {
      state.dataPlanTypeDetailsPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Data plan type created successfully!'));
    });

    // GET DATA PLANS TYPES LIST
    builder.addCase(getDataPlanTypesList.pending, state => {
      state.dataPlanTypesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getDataPlanTypesList.rejected, (state, action) => {
      state.dataPlanTypesListFetchStatus = FETCH_STATUS.REJECTED;
      state.dataPlanTypesList = null;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(getDataPlanTypesList.fulfilled, (state, action) => {
      state.dataPlanTypesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.dataPlanTypesList = action.payload;
    });

    // GET DATA PLANS TYPES DETAILS
    builder.addCase(getDataPlanTypeDetails.pending, state => {
      state.dataPlanTypeDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getDataPlanTypeDetails.rejected, (state, action) => {
      state.dataPlanTypeDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.dataPlanTypeDetails = null;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(getDataPlanTypeDetails.fulfilled, (state, action) => {
      state.dataPlanTypeDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.dataPlanTypeDetails = action.payload;
    });

    // PATCH DATA PLANS TYPES DETAILS
    builder.addCase(patchDataPlanTypeDetails.pending, state => {
      state.dataPlanTypeDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchDataPlanTypeDetails.rejected, (state, action) => {
      state.dataPlanTypeDetailsPatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(patchDataPlanTypeDetails.fulfilled, state => {
      state.dataPlanTypeDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Data plan type updated successfully!'));
    });

    // DELETE DATA PLANS TYPES DETAILS
    builder.addCase(deleteDataPlanTypeDetails.pending, state => {
      state.dataPlanTypeDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteDataPlanTypeDetails.rejected, (state, action) => {
      state.dataPlanTypeDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(deleteDataPlanTypeDetails.fulfilled, state => {
      state.dataPlanTypeDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Data plan type deleted successfully!'));
    });
  },
});

export const {
  setDataPlanDetailsDeleteStatus,
  setDataPlanTypeDetailsDeleteStatus,
  setDataPlanDetailsFetchStatus,
  setDataPlanDetailsPatchStatus,
  setDataPlanDetailsPostStatus,
  setDataPlansListFetchStatus,
  setDataPlanDetails,
  setDataPlanTypeDetailsFetchStatus,
  setDataPlanTypeDetailsPatchStatus,
  setDataPlanTypeDetailsPostStatus,
  setDataPlanTypeDetails,
  setDataPlanTypesListFetchStatus,
  setDataPlanTypesList,
  setDataPlansList,
} = dataPlansSlice.actions;
export default dataPlansSlice.reducer;
