import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { createSlice } from '@reduxjs/toolkit';
import { CountryType } from '../../types/country/country.type';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import snackbarUtils from '../../utils/SnackbarUtils';
import {
  deleteCountryDetails,
  getCountriesList,
  getCountryDetails,
  patchCountryDetails,
  patchDisableCountry,
  patchEnableCountry,
  postCountryDetails,
} from './countries.action';

export interface CountriesState {
  countriesList: null | PaginationType<CountryType>;
  countriesListFetchStatus: FETCH_STATUS;
  countriesRouteParams: RouteParamsType;
  countryDetails: null | CountryType;
  countryDetailsFetchStatus: FETCH_STATUS;
  countryDetailsPostStatus: FETCH_STATUS;
  countryDetailsPatchStatus: FETCH_STATUS;
  countryDetailsDeleteStatus: FETCH_STATUS;
  disableCountryPatchStatus: FETCH_STATUS;
  enableCountryPatchStatus: FETCH_STATUS;
}

export const initialCountriesRouteParams = {
  page: 1,
  limit: 10,
  order: '-country.id',
  q: '',
  category: '',
  status: 'all',
};

const INIT_STATE: CountriesState = {
  countriesRouteParams: initialCountriesRouteParams,
  countriesList: null,
  countriesListFetchStatus: FETCH_STATUS.NULL,
  countryDetails: null,
  countryDetailsFetchStatus: FETCH_STATUS.NULL,
  countryDetailsPostStatus: FETCH_STATUS.NULL,
  countryDetailsPatchStatus: FETCH_STATUS.NULL,
  countryDetailsDeleteStatus: FETCH_STATUS.NULL,
  disableCountryPatchStatus: FETCH_STATUS.NULL,
  enableCountryPatchStatus: FETCH_STATUS.NULL,
};

const countriesSlice = createSlice({
  name: 'countries',
  initialState: INIT_STATE,
  reducers: {
    setCountriesList: (state, action) => {
      state.countriesList = action.payload;
    },
    setCountriesListFetchStatus: (state, action) => {
      state.countriesListFetchStatus = action.payload;
    },
    setCountryDetails: (state, action) => {
      state.countryDetails = action.payload;
    },
    setCountryDetailsFetchStatus: (state, action) => {
      state.countryDetailsFetchStatus = action.payload;
    },
    setCountryDetailsPostStatus: (state, action) => {
      state.countryDetailsPostStatus = action.payload;
    },
    setCountryDetailsPatchStatus: (state, action) => {
      state.countryDetailsPatchStatus = action.payload;
    },
    setCountryDetailsDeleteStatus: (state, action) => {
      state.countryDetailsDeleteStatus = action.payload;
    },
    setDisableCountryPatchStatus: (state, action) => {
      state.disableCountryPatchStatus = action.payload;
    },
    setEnableCountryPatchStatus: (state, action) => {
      state.enableCountryPatchStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'countries') {
        state.countriesRouteParams = { ...state.countriesRouteParams, ...payload.data };
      }
    });

    // CREATE COUNTRIES DETAILS
    builder.addCase(postCountryDetails.pending, state => {
      state.countryDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postCountryDetails.rejected, (state, action) => {
      state.countryDetailsPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postCountryDetails.fulfilled, state => {
      state.countryDetailsPostStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Country has been opened!');
    });

    // GET COUNTRIES LIST
    builder.addCase(getCountriesList.pending, state => {
      state.countriesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getCountriesList.rejected, (state, action) => {
      state.countriesListFetchStatus = FETCH_STATUS.REJECTED;
      state.countriesList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getCountriesList.fulfilled, (state, action) => {
      state.countriesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.countriesList = action.payload;
    });

    // GET COUNTRIES DETAILS
    builder.addCase(getCountryDetails.pending, state => {
      state.countryDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getCountryDetails.rejected, (state, action) => {
      state.countryDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.countryDetails = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getCountryDetails.fulfilled, (state, action) => {
      state.countryDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.countryDetails = action.payload;
    });

    // PATCH COUNTRIES DETAILS
    builder.addCase(patchCountryDetails.pending, state => {
      state.countryDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchCountryDetails.rejected, (state, action) => {
      state.countryDetailsPatchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(patchCountryDetails.fulfilled, state => {
      state.countryDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Country updated successfully');
    });

    // DELETE COUNTRIES DETAILS
    builder.addCase(deleteCountryDetails.pending, state => {
      state.countryDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteCountryDetails.rejected, (state, action) => {
      state.countryDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(deleteCountryDetails.fulfilled, state => {
      state.countryDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Country deleted successfully');
    });

    // PATCH ENABLE COUNTRIES DETAILS
    builder.addCase(patchEnableCountry.pending, state => {
      state.enableCountryPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchEnableCountry.rejected, (state, action) => {
      state.enableCountryPatchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(patchEnableCountry.fulfilled, state => {
      state.enableCountryPatchStatus = FETCH_STATUS.FULFILLED;
    });

    // PATCH DISABLE COUNTRIES DETAILS
    builder.addCase(patchDisableCountry.pending, state => {
      state.disableCountryPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchDisableCountry.rejected, (state, action) => {
      state.disableCountryPatchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(patchDisableCountry.fulfilled, state => {
      state.disableCountryPatchStatus = FETCH_STATUS.FULFILLED;
    });
  },
});

export const {
  setCountryDetailsDeleteStatus,
  setCountriesListFetchStatus,
  setCountryDetailsFetchStatus,
  setCountryDetailsPatchStatus,
  setCountryDetailsPostStatus,
  setCountriesList,
  setCountryDetails,
  setEnableCountryPatchStatus,
  setDisableCountryPatchStatus,
} = countriesSlice.actions;

export default countriesSlice.reducer;
