import { SET_ROUTE_PARAMS } from '../../redux/constants';

export function setRouteParams(payload) {
  return {
    type: SET_ROUTE_PARAMS,
    payload,
  };
}

export const ROLECONSTANTS = {
  superadmin: 'superadmin',
  admin: 'admin',
  billingadmin: 'billingadmin',
  reselleradmin: 'reselleradmin',
  user: 'user',
  viewer: 'viewer',
};

const rolesArray: Array<any> = [];
Object.keys(ROLECONSTANTS).forEach(key => {
  rolesArray.push({ id: key, role: ROLECONSTANTS[key] });
});
export const RolesArray = rolesArray;

export const PERMISSIONROLECONSTANTS = {
  superadmin: 'superadmin',
  telcloud_admin: 'telcloud_admin',
  telcloud_t3_admin: 'telcloud_t3_admin',
  telcloud_support: 'telcloud_support',
  telcloud_user: 'telcloud_user',
  telcloud_viewer: 'telcloud_viewer',
  reseller_owner: 'reseller_owner',
  reseller_admin: 'reseller_admin',
  reseller_user: 'reseller_user',
  reseller_viewer: 'reseller_viewer',
  customer_owner: 'customer_owner',
  customer_admin: 'customer_admin',
  customer_user: 'customer_user',
  customer_viewer: 'customer_viewer',
};

export const getAllPermissionRolesForUserLevel = (
  userLevel: 'telcloud' | 'reseller' | 'customer',
  userTypes: null | Array<'viewer' | 'user' | 'admin' | 'owner' | 't3_admin' | 'support'> = null
): Array<any> => {
  const roles: Array<string> = [];
  Object.keys(PERMISSIONROLECONSTANTS).forEach(k => {
    if (PERMISSIONROLECONSTANTS[k].includes(userLevel)) {
      roles.push(k);
    }
  });
  if (userTypes !== null && Array.isArray(userTypes) && userTypes.length > 0) {
    const filteredRoles: Array<string> = [];
    userTypes.forEach(ut => {
      Object.keys(roles).forEach(k => {
        if (roles[k].includes(ut)) {
          filteredRoles.push(roles[k]);
        }
      });
    });

    return filteredRoles.filter(
      s =>
        s !== PERMISSIONROLECONSTANTS.telcloud_t3_admin ||
        (s === PERMISSIONROLECONSTANTS.telcloud_t3_admin && userTypes.includes('t3_admin'))
    );
  } else {
    return roles;
  }
};

const permissionRolesArray: Array<any> = [];
Object.keys(PERMISSIONROLECONSTANTS).forEach(key => {
  permissionRolesArray.push({ id: key, role: PERMISSIONROLECONSTANTS[key] });
});
export const PermissionRolesArray = permissionRolesArray;
