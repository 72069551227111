import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UtilsState {
  showModal: boolean;
  modalContent: null | { size?: string; content: JSX.Element };
  activeDir: string;
  activeThemeLayout: string;
  activeTheme: string;
  activeSidebarType: string;
  activeLogoBg: string;
  activeNavbarBg: string;
  activeSidebarBg: string;
  activeSidebarPos: string;
  activeHeaderPos: string;
  activeLayout: string;
  sidebarIsOpen: boolean;
  showSelectBoxes: boolean;
  requestCount: number;
}

const INIT_STATE: UtilsState = {
  showModal: false,
  modalContent: null,
  activeDir: 'ltr',
  activeThemeLayout: 'vertical',
  activeTheme: 'light',
  activeSidebarType: 'full',
  activeLogoBg: 'skin8',
  activeNavbarBg: 'skin8',
  activeSidebarBg: 'skin7',
  activeSidebarPos: 'fixed',
  activeHeaderPos: 'fixed',
  activeLayout: 'full',
  sidebarIsOpen: false,
  showSelectBoxes: true,
  requestCount: 0,
};

export const utilsSlice = createSlice({
  name: 'utils',
  initialState: INIT_STATE,
  reducers: {
    setModalContent: (state, action: PayloadAction<null | { size?: string; content: JSX.Element }>) => {
      state.modalContent = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      () => true,
      (state, action) => {
        if (action.type.indexOf('no-loader') >= 0) return state;

        if (
          (action.type.indexOf('FETCH') >= 0 ||
            action.type.indexOf('PATCH') >= 0 ||
            action.type.indexOf('POST') >= 0 ||
            action.type.indexOf('DELETE') >= 0 ||
            action.type.indexOf('PASSWORD_RESET') >= 0 ||
            action.type.indexOf('UPLOAD') >= 0) &&
          action.payload
        ) {
          let count = state.requestCount;
          if (action.payload.toLowerCase() === 'fetching') count++;

          if (action.payload.toLowerCase() === 'error' || action.payload.toLowerCase() === 'done')
            count = count > 0 ? --count : 0;

          state.requestCount = count;
          return;
        }

        let count = state.requestCount;
        if (action.type.indexOf('pending') >= 0) {
          count++;
        } else if (action.type.indexOf('fulfilled') >= 0 || action.type.indexOf('rejected') >= 0) {
          count = count > 0 ? --count : 0;
        }

        state.requestCount = count;
        return;
      }
    );
  },
});

export const { setShowModal, setModalContent } = utilsSlice.actions;
export default utilsSlice.reducer;
