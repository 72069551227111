import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import { setAccessToken } from './smsLogger.slice';
import { setModalContent, setShowModal } from '../utils/utils.slice';

export const getSMSLoggerList = createAsyncThunk<any, any, { state: RootState }>(
  'smsLogger/getSMSLoggerList',
  async (params, thunkAPI) => {
    try {
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/sms-logger${queryString ? `?${queryString}` : ''}`
      );
      return response.data;
    } catch (error) {
      if (+error.response?.status === 401) {
        const token = localStorage.getItem('sms-logger-token');
        localStorage.removeItem('sms-logger-token');
        thunkAPI.dispatch(setAccessToken(null));
        if (token) {
          return thunkAPI.rejectWithValue(
            error.response.data.message || 'Your session has expired. Please login again!'
          );
        }
      } else {
        return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting sms logger list!');
      }
    }
  }
);

export const getSMSSIMsList = createAsyncThunk<any, any, { state: RootState }>(
  'smsLogger/getSMSSIMsList',
  async (params, thunkAPI) => {
    try {
      const queryString = Object.keys(params || {})
        .map(key => key + '=' + params[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/sms-sims${queryString ? `?${queryString}` : ''}`
      );
      return response.data;
    } catch (error) {
      if (+error.response?.status === 401) {
        const token = localStorage.getItem('sms-logger-token');
        localStorage.removeItem('sms-logger-token');
        thunkAPI.dispatch(setAccessToken(null));
        if (token) {
          return thunkAPI.rejectWithValue(
            error.response.data.message || 'Your session has expired. Please login again!'
          );
        }
      } else {
        return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting sms sims list!');
      }
    }
  }
);

export const getSMSLoggerSummary = createAsyncThunk<any, void, { state: RootState }>(
  'smsLogger/getSMSLoggerSummary',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<any>(`${process.env.REACT_APP_API_URL}/sms-logger/summary`);
      return response.data;
    } catch (error) {
      if (+error.response?.status === 401) {
        const token = localStorage.getItem('sms-logger-token');
        localStorage.removeItem('sms-logger-token');
        thunkAPI.dispatch(setAccessToken(null));
        if (token) {
          return thunkAPI.rejectWithValue(
            error.response.data.message || 'Your session has expired. Please login again!'
          );
        }
      } else {
        return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting sms logger summary!');
      }
    }
  }
);

export const getSMSLoggerDeviceNames = createAsyncThunk<any, void, { state: RootState }>(
  'smsLogger/getSMSLoggerDeviceNames',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get<any>(`${process.env.REACT_APP_API_URL}/sms-devices/names`);
      return response.data;
    } catch (error) {
      if (+error.response?.status === 401) {
        const token = localStorage.getItem('sms-logger-token');
        localStorage.removeItem('sms-logger-token');
        thunkAPI.dispatch(setAccessToken(null));
        if (token) {
          return thunkAPI.rejectWithValue(
            error.response.data.message || 'Your session has expired. Please login again!'
          );
        }
      } else {
        return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting sms logger device names!');
      }
    }
  }
);

export const getSMSSIM = createAsyncThunk<any, { id: number }, { state: RootState }>(
  'smsLogger/getSMSSIM',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<any>(`${process.env.REACT_APP_API_URL}/sms-sims/${id}`);
      return response.data;
    } catch (error) {
      if (+error.response?.status === 401) {
        const token = localStorage.getItem('sms-logger-token');
        localStorage.removeItem('sms-logger-token');
        thunkAPI.dispatch(setAccessToken(null));
        if (token) {
          return thunkAPI.rejectWithValue(
            error.response.data.message || 'Your session has expired. Please login again!'
          );
        }
      } else {
        return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting sms sim!');
      }
    }
  }
);

export const patchSMSsimDetails = createAsyncThunk<any, { id: number; data: unknown }, { state: RootState }>(
  'smsLogger/patchSMSsimDetails',
  async ({ data, id }, thunkAPI) => {
    try {
      const response = await axios.patch<any>(`${process.env.REACT_APP_API_URL}/sms-sims/${id}`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during changing SIM!');
    }
  }
);

export const postSMSsimDetails = createAsyncThunk<any, { data: unknown }, { state: RootState }>(
  'smsLogger/postSMSsimDetails',
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post<any>(`${process.env.REACT_APP_API_URL}/sms-sims`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating SIM!');
    }
  }
);

export const deleteSMSsimDetails = createAsyncThunk<any, { id: number }, { state: RootState }>(
  'smsLogger/deleteSMSsimDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.delete<any>(`${process.env.REACT_APP_API_URL}/sms-sims/${id}}`);
      thunkAPI.dispatch(setShowModal(false));
      thunkAPI.dispatch(setModalContent(null));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting attachment!');
    }
  }
);

export const getFullSMSPlansList = createAsyncThunk<any, any, { state: RootState }>(
  'smsLogger/getFullSMSPlansList',
  async (params, thunkAPI) => {
    try {
      const mergedParams = { ...params, limit: 1000 };
      const queryString = Object.keys(mergedParams || {})
        .map(key => key + '=' + mergedParams[key])
        .join('&');

      const response = await axios.get<any>(
        `${process.env.REACT_APP_API_URL}/sms-plans${queryString ? `?${queryString}` : ''}`
      );
      return response.data;
    } catch (error) {
      if (+error.response?.status === 401) {
        const token = localStorage.getItem('sms-logger-token');
        localStorage.removeItem('sms-logger-token');
        thunkAPI.dispatch(setAccessToken(null));
        if (token) {
          return thunkAPI.rejectWithValue(
            error.response.data.message || 'Your session has expired. Please login again!'
          );
        }
      } else {
        return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting SMS plans List!');
      }
    }
  }
);
