import { createSlice } from '@reduxjs/toolkit';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { PaginationType } from '../../types/utils/pagination.type';
import { IssueTrackerListItemType, IssueTrackerType } from '../../types/issue-tracker/issue-tracker.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import snackbarUtils from '../../utils/SnackbarUtils';
import {
  getAsyncTaskList,
  getATATaskLogsList,
  getAuditLogsList,
  getBoBoRevIoQueueList,
  getDialerLogsList,
  getErrorLogsList,
  getGDMSCallbacksList,
  getIssueTrackerDetails,
  getIssueTrackerList,
  getMasterLogsList,
  getRevIoQueueList,
  getRevioReportsList,
  getRouterSyncAsyncTaskList,
  getRouterUpdateAsyncTaskList,
  getXMLRequestList,
  patchIssueTrackerDetails,
} from './logs.action';
import { ErrorLogListItemType } from '../../types/error-log/error-log.type';
import { AsyncTaskListItemType, AsyncTaskType } from '../../types/async-task/async-task.type';
import { NotificationListItemType } from '../../types/notification/notification.type';
import { MasterChangeLogType } from '../../types/master-change-log/master-change-log.type';
import { QueueLogType } from '../../types/log/log.type';
import { XmlRequestLogsType } from '../../types/xml-request-log/xml-request-log.type';
import { ATALogType } from '../../types/ata/ata-log/ata-log.type';
import { GdmsCallbackType } from '../../types/gdms-callback/gdms-callback.type';
import { DialerLogType } from '../../types/dialer/dialer-log/dialer-log.type';

export interface LogsState {
  // ISSUE TRACKER LOGS --------------------------------
  issueTrackerRouteParams: RouteParamsType;
  issueTrackerList: null | PaginationType<IssueTrackerListItemType>;
  issueTrackerListFetchStatus: FETCH_STATUS;
  issueTrackerDetails: null | IssueTrackerType;
  issueTrackerDetailsFetchStatus: FETCH_STATUS;
  issueTrackerDetailsPatchStatus: FETCH_STATUS;
  // ERROR LOGS -----------------------------------------
  errorLogsRouteParams: RouteParamsType;
  errorLogsList: null | PaginationType<ErrorLogListItemType>;
  errorLogsListFetchStatus: FETCH_STATUS;
  // ASYNC TASKS ----------------------------------------
  asyncTasksRouteParams: RouteParamsType;
  asyncTasksList: null | PaginationType<AsyncTaskListItemType>;
  asyncTasksListFetchStatus: FETCH_STATUS;
  routerSyncAsyncTasksList: null | {
    in_progress: Array<AsyncTaskType>;
    last_failed: AsyncTaskType;
    last_success: AsyncTaskType;
  };
  routerSyncAsyncTasksListFetchStatus: FETCH_STATUS;
  routerUpdateAsyncTasksList: null | {
    in_progress: Array<AsyncTaskType>;
    last_failed: AsyncTaskType;
    last_success: AsyncTaskType;
  };
  routerUpdateAsyncTasksListFetchStatus: FETCH_STATUS;
  // AUDIT LOGS -----------------------------------------
  auditLogsList: null | PaginationType<NotificationListItemType>;
  auditLogsListFetchStatus: FETCH_STATUS;
  auditLogsRouteParams: RouteParamsType;
  // REV.IO REPORTS -------------------------------------
  revioReportsList: null | PaginationType<NotificationListItemType>;
  revioReportsListFetchStatus: FETCH_STATUS;
  revioReportsRouteParams: RouteParamsType;
  // MASTER LOGS ----------------------------------------
  masterLogsList: null | PaginationType<MasterChangeLogType>;
  masterLogsListFetchStatus: FETCH_STATUS;
  masterLogsRouteParams: RouteParamsType;
  // REV.IO QUEUE ----------------------------------------
  revIoQueueList: null | PaginationType<QueueLogType>;
  revIoQueueListFetchStatus: FETCH_STATUS;
  revIoQueueRouteParams: RouteParamsType;
  // BOBO REV.IO QUEUE ----------------------------------------
  boboRevIoQueueList: null | PaginationType<QueueLogType>;
  boboRevIoQueueListFetchStatus: FETCH_STATUS;
  boboRevIoQueueRouteParams: RouteParamsType;
  // XML REQUEST -----------------------------------------
  xmlRequestRouteParams: RouteParamsType;
  xmlRequestList: null | PaginationType<XmlRequestLogsType>;
  xmlRequestListFetchStatus: FETCH_STATUS;
  // ATA TASKS LOGS -----------------------------------------
  ataTaskLogsRouteParams: RouteParamsType;
  ataTaskLogsList: null | PaginationType<ATALogType>;
  ataTaskLogsListFetchStatus: FETCH_STATUS;
  // GDMS CALLBACKS -----------------------------------------
  gdmsCallbacksRouteParams: RouteParamsType;
  gdmsCallbacksList: null | PaginationType<GdmsCallbackType>;
  gdmsCallbacksListFetchStatus: FETCH_STATUS;
  // DIALER LOGS -----------------------------------------
  dialerLogsRouteParams: RouteParamsType;
  dialerLogsList: null | PaginationType<DialerLogType>;
  dialerLogsListFetchStatus: FETCH_STATUS;
}

export const initialXMLRequestLogsRouteParams = {
  page: 1,
  limit: 10,
  order: '-xml_request_logs.created_at',
  q: '',
};

export const initialDialerLogsRouteParams = {
  page: 1,
  limit: 10,
  order: '-dialer_log.id',
  q: '',
};

export const initialATATaskLogsRouteParams = {
  page: 1,
  limit: 10,
  order: '-ata_logs.created_at',
  q: '',
};

export const initialGDMSCallbacksRouteParams = {
  page: 1,
  limit: 10,
  order: '-gdms_callback_messagess.created_at',
  q: '',
};

export const initialIssueTrackerRouteParams = {
  page: 1,
  limit: 10,
  order: '-issue_tracker_items.id',
  q: '',
  code: '',
  status: '',
};

export const initialErrorLogsRouteParams = {
  page: 1,
  limit: 10,
  order: '-error_log.id',
  q: '',
};

export const initialAsyncTasksRouteParams = {
  page: 1,
  limit: 10,
  order: '-async_task.id',
  q: '',
  type: '',
  status: '',
};

export const initialAuditLogsRouteParams = {
  page: 1,
  limit: 10,
  order: '-audit_log.id',
  q: '',
  entity_type: '',
};

export const initialRevioReportsRouteParams = {
  page: 1,
  limit: 10,
  order: '-revioVerificationReport.id',
  q: '',
};

export const initialMasterLogsRouteParams = {
  page: 1,
  limit: 10,
  order: '-audit_log.timestamp',
  q: '',
};

export const initialRevIoQueueRouteParams = {
  page: 1,
  limit: 10,
  order: '-audit_log.timestamp',
  q: '',
};

export const initialBoBoRevIoQueueRouteParams = {
  page: 1,
  limit: 10,
  order: '-audit_log.timestamp',
  q: '',
};

const INIT_STATE: LogsState = {
  // ISSUE TRACKER LOGS --------------------------------
  issueTrackerRouteParams: initialIssueTrackerRouteParams,
  issueTrackerList: null,
  issueTrackerListFetchStatus: FETCH_STATUS.NULL,
  issueTrackerDetails: null,
  issueTrackerDetailsFetchStatus: FETCH_STATUS.NULL,
  issueTrackerDetailsPatchStatus: FETCH_STATUS.NULL,
  // ERROR LOGS -----------------------------------------
  errorLogsRouteParams: initialErrorLogsRouteParams,
  errorLogsList: null,
  errorLogsListFetchStatus: FETCH_STATUS.NULL,
  // ASYNC TASKS ----------------------------------------
  asyncTasksRouteParams: initialAsyncTasksRouteParams,
  asyncTasksList: null,
  asyncTasksListFetchStatus: FETCH_STATUS.NULL,
  routerUpdateAsyncTasksList: null,
  routerUpdateAsyncTasksListFetchStatus: FETCH_STATUS.NULL,
  routerSyncAsyncTasksList: null,
  routerSyncAsyncTasksListFetchStatus: FETCH_STATUS.NULL,
  // AUDIT LOGS -----------------------------------------
  auditLogsRouteParams: initialAuditLogsRouteParams,
  auditLogsList: null,
  auditLogsListFetchStatus: FETCH_STATUS.NULL,
  // REV.IO REPORTS -------------------------------------
  revioReportsRouteParams: initialRevioReportsRouteParams,
  revioReportsList: null,
  revioReportsListFetchStatus: FETCH_STATUS.NULL,
  // MASTER LOGS ----------------------------------------
  masterLogsList: null,
  masterLogsListFetchStatus: FETCH_STATUS.NULL,
  masterLogsRouteParams: initialMasterLogsRouteParams,
  // REV.IO QUEUE ----------------------------------------
  revIoQueueList: null,
  revIoQueueListFetchStatus: FETCH_STATUS.NULL,
  revIoQueueRouteParams: initialRevIoQueueRouteParams,
  // BOBO REV.IO QUEUE ----------------------------------------
  boboRevIoQueueList: null,
  boboRevIoQueueListFetchStatus: FETCH_STATUS.NULL,
  boboRevIoQueueRouteParams: initialBoBoRevIoQueueRouteParams,
  // XML REQUEST -----------------------------------------
  xmlRequestRouteParams: initialXMLRequestLogsRouteParams,
  xmlRequestList: null,
  xmlRequestListFetchStatus: FETCH_STATUS.NULL,
  // ATA TASKS LOGS -----------------------------------------
  ataTaskLogsRouteParams: initialATATaskLogsRouteParams,
  ataTaskLogsList: null,
  ataTaskLogsListFetchStatus: FETCH_STATUS.NULL,
  // GDMS CALLBACKS -----------------------------------------
  gdmsCallbacksRouteParams: initialGDMSCallbacksRouteParams,
  gdmsCallbacksList: null,
  gdmsCallbacksListFetchStatus: FETCH_STATUS.NULL,
  // DIALER LOGS -----------------------------------------
  dialerLogsRouteParams: initialDialerLogsRouteParams,
  dialerLogsList: null,
  dialerLogsListFetchStatus: FETCH_STATUS.NULL,
};

export const logsSlice = createSlice({
  name: 'logs',
  initialState: INIT_STATE,
  reducers: {
    // ISSUE TRACKER LOGS --------------------------------
    setIssueTrackerList: (state, action) => {
      state.issueTrackerList = action.payload;
    },
    setIssueTrackerListFetchStatus: (state, action) => {
      state.issueTrackerListFetchStatus = action.payload;
    },
    setIssueTrackerDetails: (state, action) => {
      state.issueTrackerDetails = action.payload;
    },
    setIssueTrackerDetailsFetchStatus: (state, action) => {
      state.issueTrackerDetailsFetchStatus = action.payload;
    },
    setIssueTrackerDetailsPatchStatus: (state, action) => {
      state.issueTrackerDetailsPatchStatus = action.payload;
    },
    // ERROR LOGS -----------------------------------------
    setErrorLogsList: (state, action) => {
      state.errorLogsList = action.payload;
    },
    setErrorLogsListFetchStatus: (state, action) => {
      state.errorLogsListFetchStatus = action.payload;
    },
    // ASYNC TASKS ----------------------------------------
    setAsyncTasksList: (state, action) => {
      state.asyncTasksList = action.payload;
    },
    setAsyncTasksListFetchStatus: (state, action) => {
      state.asyncTasksListFetchStatus = action.payload;
    },
    setRouterSyncAsyncTasksList: (state, action) => {
      state.routerSyncAsyncTasksList = action.payload;
    },
    setRouterSyncAsyncTasksListFetchStatus: (state, action) => {
      state.routerSyncAsyncTasksListFetchStatus = action.payload;
    },
    setRouterUpdateAsyncTasksList: (state, action) => {
      state.routerUpdateAsyncTasksList = action.payload;
    },
    setRouterUpdateAsyncTasksListFetchStatus: (state, action) => {
      state.routerUpdateAsyncTasksListFetchStatus = action.payload;
    },
    // AUDIT LOGS -----------------------------------------
    setAuditLogsList: (state, action) => {
      state.auditLogsList = action.payload;
    },
    setAuditLogsListFetchStatus: (state, action) => {
      state.auditLogsListFetchStatus = action.payload;
    },
    // REV.IO REPORTS -------------------------------------
    setRevioReportsList: (state, action) => {
      state.revioReportsList = action.payload;
    },
    setRevioReportsListFetchStatus: (state, action) => {
      state.revioReportsListFetchStatus = action.payload;
    },
    // MASTER LOGS ----------------------------------------
    setMasterLogsList: (state, action) => {
      state.masterLogsList = action.payload;
    },
    setMasterLogsListFetchStatus: (state, action) => {
      state.masterLogsListFetchStatus = action.payload;
    },
    // REV.IO QUEUE ----------------------------------------
    setRevIoQueueList: (state, action) => {
      state.revIoQueueList = action.payload;
    },
    setRevIoQueueListFetchStatus: (state, action) => {
      state.revIoQueueListFetchStatus = action.payload;
    },
    // BOBO REV.IO QUEUE ----------------------------------------
    setBoBoRevIoQueueList: (state, action) => {
      state.boboRevIoQueueList = action.payload;
    },
    setBoBoRevIoQueueListFetchStatus: (state, action) => {
      state.boboRevIoQueueListFetchStatus = action.payload;
    },
    // XML REQUEST -----------------------------------------
    setXMLRequestList: (state, action) => {
      state.xmlRequestList = action.payload;
    },
    setXMLRequestListFetchStatus: (state, action) => {
      state.xmlRequestListFetchStatus = action.payload;
    },
    // ATA TASKS LOGS -----------------------------------------
    setATATaskLogsList: (state, action) => {
      state.ataTaskLogsList = action.payload;
    },
    setATATaskLogsListFetchStatus: (state, action) => {
      state.ataTaskLogsListFetchStatus = action.payload;
    },
    // GDMS CALLBACKS -----------------------------------------
    setGDMSCallbacksList: (state, action) => {
      state.gdmsCallbacksList = action.payload;
    },
    setGDMSCallbacksListFetchStatus: (state, action) => {
      state.gdmsCallbacksListFetchStatus = action.payload;
    },
    // GDMS CALLBACKS -----------------------------------------
    setDialerLogsList: (state, action) => {
      state.dialerLogsList = action.payload;
    },
    setDialerLogsListFetchStatus: (state, action) => {
      state.dialerLogsListFetchStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'issueTracker') {
        state.issueTrackerRouteParams = { ...state.issueTrackerRouteParams, ...payload.data };
      }

      if (payload.reducer === 'errorLogs') {
        state.errorLogsRouteParams = { ...state.errorLogsRouteParams, ...payload.data };
      }

      if (payload.reducer === 'asyncTasks') {
        state.asyncTasksRouteParams = { ...state.asyncTasksRouteParams, ...payload.data };
      }

      if (payload.reducer === 'auditLogs') {
        state.auditLogsRouteParams = { ...state.auditLogsRouteParams, ...payload.data };
      }
      if (payload.reducer === 'revioReports') {
        state.revioReportsRouteParams = { ...state.revioReportsRouteParams, ...payload.data };
      }
      if (payload.reducer === 'masterLogs') {
        state.revioReportsRouteParams = { ...state.revioReportsRouteParams, ...payload.data };
      }
      if (payload.reducer === 'revIoQueue') {
        state.revIoQueueRouteParams = { ...state.revIoQueueRouteParams, ...payload.data };
      }
      if (payload.reducer === 'boboRevIoQueue') {
        state.boboRevIoQueueRouteParams = { ...state.boboRevIoQueueRouteParams, ...payload.data };
      }
      if (payload.reducer === 'ataTaskLogs') {
        state.ataTaskLogsRouteParams = { ...state.ataTaskLogsRouteParams, ...payload.data };
      }
      if (payload.reducer === 'gdmsCallbackLogs') {
        state.gdmsCallbacksRouteParams = { ...state.gdmsCallbacksRouteParams, ...payload.data };
      }
      if (payload.reducer === 'xmlRequestLogs') {
        state.xmlRequestRouteParams = { ...state.xmlRequestRouteParams, ...payload.data };
      }
      if (payload.reducer === 'dialerLogs') {
        state.dialerLogsRouteParams = { ...state.dialerLogsRouteParams, ...payload.data };
      }
    });

    // ISSUE TRACKER LOGS --------------------------------

    // GET ISSUE LIST
    builder.addCase(getIssueTrackerList.pending, state => {
      state.issueTrackerListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getIssueTrackerList.rejected, (state, action) => {
      state.issueTrackerListFetchStatus = FETCH_STATUS.REJECTED;
      state.issueTrackerList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getIssueTrackerList.fulfilled, (state, action) => {
      state.issueTrackerListFetchStatus = FETCH_STATUS.FULFILLED;
      state.issueTrackerList = action.payload;
    });

    // GET ISSUE DETAILS
    builder.addCase(getIssueTrackerDetails.pending, state => {
      state.issueTrackerDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getIssueTrackerDetails.rejected, (state, action) => {
      state.issueTrackerDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.issueTrackerDetails = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getIssueTrackerDetails.fulfilled, (state, action) => {
      state.issueTrackerDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.issueTrackerDetails = action.payload;
    });

    // PATCH ISSUE
    builder.addCase(patchIssueTrackerDetails.pending, state => {
      state.issueTrackerDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchIssueTrackerDetails.rejected, (state, action) => {
      state.issueTrackerDetailsPatchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(patchIssueTrackerDetails.fulfilled, state => {
      state.issueTrackerDetailsPatchStatus = FETCH_STATUS.FULFILLED;
    });

    // ERROR LOGS -----------------------------------------

    // GET ERROR LOGS LIST
    builder.addCase(getErrorLogsList.pending, state => {
      state.errorLogsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getErrorLogsList.rejected, (state, action) => {
      state.errorLogsListFetchStatus = FETCH_STATUS.REJECTED;
      state.errorLogsList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getErrorLogsList.fulfilled, (state, action) => {
      state.errorLogsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.errorLogsList = action.payload;
    });

    // ASYNC TASKS ----------------------------------------

    // GET ASYNC TASKS LIST
    builder.addCase(getAsyncTaskList.pending, state => {
      state.asyncTasksListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getAsyncTaskList.rejected, (state, action) => {
      state.asyncTasksListFetchStatus = FETCH_STATUS.REJECTED;
      state.asyncTasksList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getAsyncTaskList.fulfilled, (state, action) => {
      state.asyncTasksListFetchStatus = FETCH_STATUS.FULFILLED;
      state.asyncTasksList = action.payload;
    });

    // GET ROUTER SYNC ASYNC TASKS LIST
    builder.addCase(getRouterSyncAsyncTaskList.pending, state => {
      state.routerSyncAsyncTasksListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getRouterSyncAsyncTaskList.rejected, (state, action) => {
      state.routerSyncAsyncTasksListFetchStatus = FETCH_STATUS.REJECTED;
      state.routerSyncAsyncTasksList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getRouterSyncAsyncTaskList.fulfilled, (state, action) => {
      state.routerSyncAsyncTasksListFetchStatus = FETCH_STATUS.FULFILLED;
      state.routerSyncAsyncTasksList = action.payload;
    });

    // GET ROUTER UPDATE ASYNC TASKS LIST
    builder.addCase(getRouterUpdateAsyncTaskList.pending, state => {
      state.routerUpdateAsyncTasksListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getRouterUpdateAsyncTaskList.rejected, (state, action) => {
      state.routerUpdateAsyncTasksListFetchStatus = FETCH_STATUS.REJECTED;
      state.routerUpdateAsyncTasksList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getRouterUpdateAsyncTaskList.fulfilled, (state, action) => {
      state.routerUpdateAsyncTasksListFetchStatus = FETCH_STATUS.FULFILLED;
      state.routerUpdateAsyncTasksList = action.payload;
    });

    // AUDIT LOGS -----------------------------------------

    // GET AUDIT LOGS LIST
    builder.addCase(getAuditLogsList.pending, state => {
      state.auditLogsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getAuditLogsList.rejected, (state, action) => {
      state.auditLogsListFetchStatus = FETCH_STATUS.REJECTED;
      state.auditLogsList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getAuditLogsList.fulfilled, (state, action) => {
      state.auditLogsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.auditLogsList = action.payload;
    });

    // REV.IO REPORTS -------------------------------------

    // GET REV.IO REPORTS LIST
    builder.addCase(getRevioReportsList.pending, state => {
      state.revioReportsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getRevioReportsList.rejected, (state, action) => {
      state.revioReportsListFetchStatus = FETCH_STATUS.REJECTED;
      state.revioReportsList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getRevioReportsList.fulfilled, (state, action) => {
      state.revioReportsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.revioReportsList = action.payload;
    });

    // MASTER LOGS ----------------------------------------
    builder.addCase(getMasterLogsList.pending, state => {
      state.masterLogsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getMasterLogsList.rejected, (state, action) => {
      state.masterLogsListFetchStatus = FETCH_STATUS.REJECTED;
      state.masterLogsList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getMasterLogsList.fulfilled, (state, action) => {
      state.masterLogsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.masterLogsList = action.payload;
    });
    // REV.IO QUEUE ----------------------------------------
    builder.addCase(getRevIoQueueList.pending, state => {
      state.revIoQueueListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getRevIoQueueList.rejected, (state, action) => {
      state.revIoQueueListFetchStatus = FETCH_STATUS.REJECTED;
      state.revIoQueueList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getRevIoQueueList.fulfilled, (state, action) => {
      state.revIoQueueListFetchStatus = FETCH_STATUS.FULFILLED;
      state.revIoQueueList = action.payload;
    });
    // BOBO REV.IO QUEUE ----------------------------------------
    builder.addCase(getBoBoRevIoQueueList.pending, state => {
      state.boboRevIoQueueListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getBoBoRevIoQueueList.rejected, (state, action) => {
      state.boboRevIoQueueListFetchStatus = FETCH_STATUS.REJECTED;
      state.boboRevIoQueueList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getBoBoRevIoQueueList.fulfilled, (state, action) => {
      state.boboRevIoQueueListFetchStatus = FETCH_STATUS.FULFILLED;
      state.boboRevIoQueueList = action.payload;
    });
    // ATA TASK LOGS LIST ----------------------------------------
    builder.addCase(getATATaskLogsList.pending, state => {
      state.ataTaskLogsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getATATaskLogsList.rejected, (state, action) => {
      state.ataTaskLogsListFetchStatus = FETCH_STATUS.REJECTED;
      state.ataTaskLogsList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getATATaskLogsList.fulfilled, (state, action) => {
      state.ataTaskLogsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.ataTaskLogsList = action.payload;
    });
    // GDMS CALLBACKS ----------------------------------------
    builder.addCase(getGDMSCallbacksList.pending, state => {
      state.gdmsCallbacksListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getGDMSCallbacksList.rejected, (state, action) => {
      state.gdmsCallbacksListFetchStatus = FETCH_STATUS.REJECTED;
      state.gdmsCallbacksList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getGDMSCallbacksList.fulfilled, (state, action) => {
      state.gdmsCallbacksListFetchStatus = FETCH_STATUS.FULFILLED;
      state.gdmsCallbacksList = action.payload;
    });
    // XML REQUEST LIST ----------------------------------------
    builder.addCase(getXMLRequestList.pending, state => {
      state.xmlRequestListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getXMLRequestList.rejected, (state, action) => {
      state.xmlRequestListFetchStatus = FETCH_STATUS.REJECTED;
      state.xmlRequestList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getXMLRequestList.fulfilled, (state, action) => {
      state.xmlRequestListFetchStatus = FETCH_STATUS.FULFILLED;
      state.xmlRequestList = action.payload;
    });
    // DIALER LOGS LIST ----------------------------------------
    builder.addCase(getDialerLogsList.pending, state => {
      state.dialerLogsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getDialerLogsList.rejected, (state, action) => {
      state.dialerLogsListFetchStatus = FETCH_STATUS.REJECTED;
      state.dialerLogsList = null;
      setTimeout(() => {
        snackbarUtils.error(action.payload as string);
      });
    });
    builder.addCase(getDialerLogsList.fulfilled, (state, action) => {
      state.dialerLogsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.dialerLogsList = action.payload;
    });
  },
});

export const {
  setIssueTrackerListFetchStatus,
  setIssueTrackerDetailsPatchStatus,
  setIssueTrackerList,
  setIssueTrackerDetailsFetchStatus,
  setIssueTrackerDetails,
  setErrorLogsList,
  setErrorLogsListFetchStatus,
  setAsyncTasksList,
  setAsyncTasksListFetchStatus,
  setRouterSyncAsyncTasksListFetchStatus,
  setRouterUpdateAsyncTasksListFetchStatus,
  setRouterUpdateAsyncTasksList,
  setRouterSyncAsyncTasksList,
  setRevioReportsList,
  setAuditLogsList,
  setAuditLogsListFetchStatus,
  setRevioReportsListFetchStatus,
  setMasterLogsListFetchStatus,
  setMasterLogsList,
  setBoBoRevIoQueueListFetchStatus,
  setRevIoQueueListFetchStatus,
  setRevIoQueueList,
  setBoBoRevIoQueueList,
  setATATaskLogsListFetchStatus,
  setGDMSCallbacksListFetchStatus,
  setXMLRequestListFetchStatus,
  setGDMSCallbacksList,
  setXMLRequestList,
  setATATaskLogsList,
  setDialerLogsListFetchStatus,
  setDialerLogsList,
} = logsSlice.actions;

export default logsSlice.reducer;
