export enum EXTERNAL_SYSTEM_INSTANCE_PROVIDER {
  SKYSWITCH_PBX = 'SKYSWITCH_PBX',
  SKYSWITCH_TELCO = 'P00',
  NETSAPIENS_PBX = 'NETSAPIENS_PBX',
  RING_CENTRAL = 'RING_CENTRAL',
  BANDWIDTH_COM = 'BANDWIDTH_COM',
  TWILIO = 'TWILIO',
  QUESTBLUE = 'P03',
  SINCH = 'SinchFAX',
  MEGASWITCH_PBX = 'MegaSwitch-US',
  FAX_BACK_PBX = 'SecureFAX',
  SECURE_ALARM = 'P007',
  BYO_TELCO = 'BYO',
  BYO_PBX = 'BYO_PBX',
  ROUTE_TRUST_TELCO = 'P04',
}
