import { PaginationType } from '../../types/utils/pagination.type';
import { EnclosureType } from '../../types/enclosure/enclosure.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { createSlice } from '@reduxjs/toolkit';
import { EnclosureTypeType } from '../../types/enclosure/enclosure-type/enclosure-type.type';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import snackbarUtils from '../../utils/SnackbarUtils';
import {
  deleteEnclosureDetails,
  deleteEnclosureTypeDetails,
  getEnclosureDetails,
  getEnclosuresList,
  getEnclosureTypeDetails,
  getEnclosureTypesList,
  patchEnclosureDetails,
  patchEnclosureTypeDetails,
  postEnclosureDetails,
  postEnclosures,
  postEnclosureTypeDetails,
} from './enclosures.action';

export interface EnclosuresState {
  enclosuresPostStatus: FETCH_STATUS;
  enclosuresBulk: null | Array<unknown>;
  enclosuresList: null | PaginationType<EnclosureType>;
  enclosuresListFetchStatus: FETCH_STATUS;
  enclosuresRouteParams: RouteParamsType;
  enclosureDetails: null | EnclosureType;
  enclosureDetailsFetchStatus: FETCH_STATUS;
  enclosureDetailsPostStatus: FETCH_STATUS;
  enclosureDetailsPatchStatus: FETCH_STATUS;
  enclosureDetailsDeleteStatus: FETCH_STATUS;

  enclosureTypesList: null | PaginationType<EnclosureTypeType>;
  enclosureTypesListFetchStatus: FETCH_STATUS;
  enclosureTypesRouteParams: RouteParamsType;
  enclosureTypeDetails: null | EnclosureTypeType;
  enclosureTypeDetailsFetchStatus: FETCH_STATUS;
  enclosureTypeDetailsPostStatus: FETCH_STATUS;
  enclosureTypeDetailsPatchStatus: FETCH_STATUS;
  enclosureTypeDetailsDeleteStatus: FETCH_STATUS;
}

export const initialEnclosuresRouteParams = {
  page: 1,
  limit: 10,
  order: '-enclosure.id',
  q: '',
  equipment_status: 'all',
  warehouse_id: '',
  in_service: 'all',
  country_id: '',
  country_category: '',
};

export const initialEnclosureTypesRouteParams = {
  page: 1,
  limit: 10,
  order: '-enclosure_type.id',
  q: '',
};

const INIT_STATE: EnclosuresState = {
  enclosuresPostStatus: FETCH_STATUS.NULL,
  enclosuresBulk: null,
  enclosuresRouteParams: initialEnclosuresRouteParams,
  enclosuresList: null,
  enclosuresListFetchStatus: FETCH_STATUS.NULL,
  enclosureDetails: null,
  enclosureDetailsFetchStatus: FETCH_STATUS.NULL,
  enclosureDetailsPostStatus: FETCH_STATUS.NULL,
  enclosureDetailsPatchStatus: FETCH_STATUS.NULL,
  enclosureDetailsDeleteStatus: FETCH_STATUS.NULL,

  enclosureTypesRouteParams: initialEnclosureTypesRouteParams,
  enclosureTypesList: null,
  enclosureTypesListFetchStatus: FETCH_STATUS.NULL,
  enclosureTypeDetails: null,
  enclosureTypeDetailsFetchStatus: FETCH_STATUS.NULL,
  enclosureTypeDetailsPostStatus: FETCH_STATUS.NULL,
  enclosureTypeDetailsPatchStatus: FETCH_STATUS.NULL,
  enclosureTypeDetailsDeleteStatus: FETCH_STATUS.NULL,
};

const enclosuresSlice = createSlice({
  name: 'enclosures',
  initialState: INIT_STATE,
  reducers: {
    setEnclosuresList: (state, action) => {
      state.enclosuresList = action.payload;
    },
    setEnclosuresListFetchStatus: (state, action) => {
      state.enclosuresListFetchStatus = action.payload;
    },
    setEnclosureDetails: (state, action) => {
      state.enclosureDetails = action.payload;
    },
    setEnclosureDetailsFetchStatus: (state, action) => {
      state.enclosureDetailsFetchStatus = action.payload;
    },
    setEnclosureDetailsPostStatus: (state, action) => {
      state.enclosureDetailsPostStatus = action.payload;
    },
    setEnclosureDetailsPatchStatus: (state, action) => {
      state.enclosureDetailsPatchStatus = action.payload;
    },
    setEnclosureDetailsDeleteStatus: (state, action) => {
      state.enclosureDetailsDeleteStatus = action.payload;
    },
    setEnclosureTypesList: (state, action) => {
      state.enclosureTypesList = action.payload;
    },
    setEnclosureTypesListFetchStatus: (state, action) => {
      state.enclosureTypesListFetchStatus = action.payload;
    },
    setEnclosureTypeDetails: (state, action) => {
      state.enclosureTypeDetails = action.payload;
    },
    setEnclosureTypeDetailsFetchStatus: (state, action) => {
      state.enclosureTypeDetailsFetchStatus = action.payload;
    },
    setEnclosureTypeDetailsPostStatus: (state, action) => {
      state.enclosureTypeDetailsPostStatus = action.payload;
    },
    setEnclosureTypeDetailsPatchStatus: (state, action) => {
      state.enclosureTypeDetailsPatchStatus = action.payload;
    },
    setEnclosureTypeDetailsDeleteStatus: (state, action) => {
      state.enclosureTypeDetailsDeleteStatus = action.payload;
    },
    setEnclosuresPostStatus: (state, action) => {
      state.enclosuresPostStatus = action.payload;
    },
    setEnclosuresBulk: (state, action) => {
      state.enclosuresBulk = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'enclosures') {
        state.enclosuresRouteParams = { ...state.enclosuresRouteParams, ...payload.data };
      }

      if (payload.reducer === 'enclosureTypes') {
        state.enclosureTypesRouteParams = { ...state.enclosureTypesRouteParams, ...payload.data };
      }
    });

    // CREATE ENCLOSURES DETAILS
    builder.addCase(postEnclosureDetails.pending, state => {
      state.enclosureDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postEnclosureDetails.rejected, (state, action) => {
      state.enclosureDetailsPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postEnclosureDetails.fulfilled, state => {
      state.enclosureDetailsPostStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Enclosure created successfully!');
    });

    // CREATE ENCLOSURES DETAILS
    builder.addCase(postEnclosures.pending, state => {
      state.enclosuresPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postEnclosures.rejected, (state, action) => {
      state.enclosuresPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postEnclosures.fulfilled, state => {
      state.enclosuresPostStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Enclosures created successfully!');
    });

    // GET ENCLOSURES LIST
    builder.addCase(getEnclosuresList.pending, state => {
      state.enclosuresListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getEnclosuresList.rejected, (state, action) => {
      state.enclosuresListFetchStatus = FETCH_STATUS.REJECTED;
      state.enclosuresList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getEnclosuresList.fulfilled, (state, action) => {
      state.enclosuresListFetchStatus = FETCH_STATUS.FULFILLED;
      state.enclosuresList = action.payload;
    });

    // GET ENCLOSURES DETAILS
    builder.addCase(getEnclosureDetails.pending, state => {
      state.enclosureDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getEnclosureDetails.rejected, (state, action) => {
      state.enclosureDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.enclosureDetails = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getEnclosureDetails.fulfilled, (state, action) => {
      state.enclosureDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.enclosureDetails = action.payload;
    });

    // PATCH ENCLOSURES DETAILS
    builder.addCase(patchEnclosureDetails.pending, state => {
      state.enclosureDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchEnclosureDetails.rejected, (state, action) => {
      state.enclosureDetailsPatchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(patchEnclosureDetails.fulfilled, state => {
      state.enclosureDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Enclosure updated successfully!');
    });

    // DELETE ENCLOSURES DETAILS
    builder.addCase(deleteEnclosureDetails.pending, state => {
      state.enclosureDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteEnclosureDetails.rejected, (state, action) => {
      state.enclosureDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(deleteEnclosureDetails.fulfilled, state => {
      state.enclosureDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Enclosure deleted successfully!');
    });

    // CREATE ENCLOSURE TYPES DETAILS
    builder.addCase(postEnclosureTypeDetails.pending, state => {
      state.enclosureTypeDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postEnclosureTypeDetails.rejected, (state, action) => {
      state.enclosureTypeDetailsPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postEnclosureTypeDetails.fulfilled, state => {
      state.enclosureTypeDetailsPostStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Enclosure type created successfully!');
    });

    // GET ENCLOSURE TYPES LIST
    builder.addCase(getEnclosureTypesList.pending, state => {
      state.enclosureTypesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getEnclosureTypesList.rejected, (state, action) => {
      state.enclosureTypesListFetchStatus = FETCH_STATUS.REJECTED;
      state.enclosureTypesList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getEnclosureTypesList.fulfilled, (state, action) => {
      state.enclosureTypesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.enclosureTypesList = action.payload;
    });

    // GET ENCLOSURE TYPES DETAILS
    builder.addCase(getEnclosureTypeDetails.pending, state => {
      state.enclosureTypeDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getEnclosureTypeDetails.rejected, (state, action) => {
      state.enclosureTypeDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.enclosureTypeDetails = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getEnclosureTypeDetails.fulfilled, (state, action) => {
      state.enclosureTypeDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.enclosureTypeDetails = action.payload;
    });

    // PATCH ENCLOSURE TYPES DETAILS
    builder.addCase(patchEnclosureTypeDetails.pending, state => {
      state.enclosureTypeDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchEnclosureTypeDetails.rejected, (state, action) => {
      state.enclosureTypeDetailsPatchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(patchEnclosureTypeDetails.fulfilled, state => {
      state.enclosureTypeDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Enclosure type updated successfully!');
    });

    // DELETE ENCLOSURE TYPES DETAILS
    builder.addCase(deleteEnclosureTypeDetails.pending, state => {
      state.enclosureTypeDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteEnclosureTypeDetails.rejected, (state, action) => {
      state.enclosureTypeDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(deleteEnclosureTypeDetails.fulfilled, state => {
      state.enclosureTypeDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Enclosure type deleted successfully!');
    });
  },
});

export const {
  setEnclosureDetailsDeleteStatus,
  setEnclosureDetailsFetchStatus,
  setEnclosureDetailsPatchStatus,
  setEnclosureDetailsPostStatus,
  setEnclosuresListFetchStatus,
  setEnclosureDetails,
  setEnclosuresList,
  setEnclosureTypeDetails,
  setEnclosureTypeDetailsFetchStatus,
  setEnclosureTypeDetailsPatchStatus,
  setEnclosureTypesList,
  setEnclosureTypeDetailsPostStatus,
  setEnclosureTypesListFetchStatus,
  setEnclosureTypeDetailsDeleteStatus,
  setEnclosuresPostStatus,
  setEnclosuresBulk,
} = enclosuresSlice.actions;

export default enclosuresSlice.reducer;
