import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { additionalQueryParams } from '../../utils/utilities';
import { setModalContent, setShowModal } from '../utils/utils.slice';
import { TicketType } from '../../types/ticket/ticket.type';
import { TicketListItemType } from '../../types/ticket/TicketListItem.type';
import { UserType } from '../../types/user/user.type';
import { TICKET_TYPE } from '../../utils/constants';
import { TicketNoteType } from '../../types/ticket/ticket-note/ticket-note.type';
import { getOrderDetails } from '../orders/orders.action';
import { getPortingDetails } from '../porting/porting.action';

export const postTicketDetails = createAsyncThunk<TicketType, { data: unknown }, { state: RootState }>(
  'tickets/postTicketDetails',
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post<TicketType>(`${process.env.REACT_APP_API_URL}/tickets`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating ticket!');
    }
  }
);

export const getTicketsList = createAsyncThunk<
  PaginationType<TicketListItemType>,
  RouteParamsType,
  { state: RootState }
>('tickets/getTicketsList', async (params, thunkAPI) => {
  try {
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      thunkAPI.getState().sharedReducer.selectedLocation,
      thunkAPI.getState().sharedReducer.includeSuborgs
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<TicketListItemType>>(
      `${process.env.REACT_APP_API_URL}/tickets${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching tickets!');
  }
});

export const getTicketDetails = createAsyncThunk<TicketType, { id: number }, { state: RootState }>(
  'tickets/getTicketDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<TicketType>(`${process.env.REACT_APP_API_URL}/tickets/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching ticket!');
    }
  }
);

export const patchTicketDetails = createAsyncThunk<TicketType, { id: number; data: unknown }, { state: RootState }>(
  'tickets/patchTicketDetails',
  async ({ data, id }, thunkAPI) => {
    try {
      const response = await axios.patch<TicketType>(`${process.env.REACT_APP_API_URL}/tickets/${id}`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating ticket!');
    }
  }
);

export const deleteTicketDetails = createAsyncThunk<
  TicketType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('tickets/deleteTicketDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<TicketType>(`${process.env.REACT_APP_API_URL}/tickets/${id}`);
    if (fetchList) thunkAPI.dispatch(getTicketsList(thunkAPI.getState().ticketsReducer.ticketsRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting ticket!');
  }
});

export const getTicketUsersList = createAsyncThunk<{ items: Array<UserType> }, { id: number }, { state: RootState }>(
  'tickets/getTicketUsersList',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<{
        items: Array<UserType>;
      }>(`${process.env.REACT_APP_API_URL}/users/ticket?org_id=${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching ticket users list!');
    }
  }
);

export const postCommentDetails = createAsyncThunk<TicketType, { data: unknown; type?: string }, { state: RootState }>(
  'comments/postCommentDetails',
  async ({ data, type }, thunkAPI) => {
    try {
      const response = await axios.post<TicketType>(`${process.env.REACT_APP_API_URL}/comments`, data);
      if (type === TICKET_TYPE.ORDER) {
        thunkAPI.dispatch(getOrderDetails({ id: thunkAPI.getState().ordersReducer.orderDetails.id }));
      } else if (type === TICKET_TYPE.PORT_REQUEST) {
        thunkAPI.dispatch(getPortingDetails({ id: thunkAPI.getState().portingsReducer.portingDetails.id }));
      } else {
        thunkAPI.dispatch(getTicketDetails({ id: thunkAPI.getState().ticketsReducer.ticketDetails.id }));
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating comment!');
    }
  }
);

export const postAttachmentDetails = createAsyncThunk<
  TicketType,
  { payload: { ticket: { id: number } }; data: unknown },
  { state: RootState }
>('comments/postAttachmentDetails', async ({ data, payload }, thunkAPI) => {
  try {
    const response = await axios.post<TicketType>(`${process.env.REACT_APP_API_URL}/attachments`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (payload.ticket) {
      thunkAPI.dispatch(getTicketDetails({ id: payload.ticket.id }));
    }

    thunkAPI.dispatch(setModalContent(null));
    thunkAPI.dispatch(setShowModal(false));

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating attachment!');
  }
});

export const deleteAttachmentDetails = createAsyncThunk<TicketType, { id: number }, { state: RootState }>(
  'tickets/deleteAttachmentDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.delete<TicketType>(`${process.env.REACT_APP_API_URL}/attachments/${id}`);
      thunkAPI.dispatch(setShowModal(false));
      thunkAPI.dispatch(setModalContent(null));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting attachment!');
    }
  }
);

export const postTicketNoteDetails = createAsyncThunk<
  TicketNoteType,
  { data: { ticket: { id: number } }; type?: string },
  { state: RootState }
>('note/postTicketNoteDetails', async ({ data, type }, thunkAPI) => {
  try {
    const response = await axios.post<TicketNoteType>(
      `${process.env.REACT_APP_API_URL}/tickets/${data.ticket.id}/note/`,
      data
    );
    if (type === TICKET_TYPE.ORDER) {
      thunkAPI.dispatch(getOrderDetails({ id: thunkAPI.getState().ordersReducer.orderDetails.id }));
    } else if (type === TICKET_TYPE.PORT_REQUEST) {
      thunkAPI.dispatch(getPortingDetails({ id: thunkAPI.getState().portingsReducer.portingDetails.id }));
    } else {
      thunkAPI.dispatch(getTicketDetails({ id: thunkAPI.getState().ticketsReducer.ticketDetails.id }));
    }
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating note!');
  }
});
