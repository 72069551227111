import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { additionalQueryParams } from '../../utils/utilities';
import { setModalContent, setShowModal } from '../utils/utils.slice';
import { OrderType } from '../../types/order/order.type';

export const postOrderDetails = createAsyncThunk<OrderType, { data: unknown }, { state: RootState }>(
  'orders/postOrderDetails',
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post<OrderType>(`${process.env.REACT_APP_API_URL}/orders`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating order!');
    }
  }
);

export const getOrdersList = createAsyncThunk<PaginationType<OrderType>, RouteParamsType, { state: RootState }>(
  'orders/getOrdersList',
  async (params, thunkAPI) => {
    try {
      const additionalParams = additionalQueryParams(
        thunkAPI.getState().sharedReducer.selectedCompany,
        thunkAPI.getState().sharedReducer.selectedLocation,
        thunkAPI.getState().sharedReducer.includeSuborgs
      );
      const mergedParams = { ...params, ...additionalParams };
      const queryString = Object.keys(mergedParams)
        .map(key => key + '=' + mergedParams[key])
        .join('&');

      const response = await axios.get<PaginationType<OrderType>>(
        `${process.env.REACT_APP_API_URL}/orders${queryString ? `?${queryString}` : ''}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching orders!');
    }
  }
);

export const getOrderDetails = createAsyncThunk<OrderType, { id: number }, { state: RootState }>(
  'orders/getOrderDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<OrderType>(`${process.env.REACT_APP_API_URL}/orders/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching order!');
    }
  }
);

export const patchOrderDetails = createAsyncThunk<OrderType, { id: number; data: unknown }, { state: RootState }>(
  'orders/patchOrderDetails',
  async ({ data, id }, thunkAPI) => {
    try {
      const response = await axios.patch<OrderType>(`${process.env.REACT_APP_API_URL}/orders/${id}`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating order!');
    }
  }
);

export const deleteOrderDetails = createAsyncThunk<OrderType, { id: number; fetchList: boolean }, { state: RootState }>(
  'orders/deleteOrderDetails',
  async ({ id, fetchList }, thunkAPI) => {
    try {
      const response = await axios.delete<OrderType>(`${process.env.REACT_APP_API_URL}/orders/${id}`);
      if (fetchList) thunkAPI.dispatch(getOrdersList(thunkAPI.getState().ordersReducer.ordersRouteParams));
      thunkAPI.dispatch(setShowModal(false));
      thunkAPI.dispatch(setModalContent(null));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting order!');
    }
  }
);

export const postOrdersTrackingInfoEmail = createAsyncThunk<OrderType, { id: number }, { state: RootState }>(
  'orders/postOrdersTrackingInfoEmail',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.post<OrderType>(`${process.env.REACT_APP_API_URL}/orders/${id}/tracking-info-email`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during sending tracking info email!');
    }
  }
);

export const postOrdersSimActivationEmail = createAsyncThunk<OrderType, { id: number }, { state: RootState }>(
  'orders/postOrdersSimActivationEmail',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<OrderType>(`${process.env.REACT_APP_API_URL}/orders/${id}/sim-activation-email`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during sending sim activation email!');
    }
  }
);

export const deleteOrderFile = createAsyncThunk<OrderType, { id: number; order_file_id: number }, { state: RootState }>(
  'orders/deleteOrderFile',
  async ({ id, order_file_id }, thunkAPI) => {
    try {
      const response = await axios.delete<OrderType>(
        `${process.env.REACT_APP_API_URL}/orders/${id}/order-file/${order_file_id}`
      );
      thunkAPI.dispatch(setShowModal(false));
      thunkAPI.dispatch(setModalContent(null));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting order file!');
    }
  }
);
