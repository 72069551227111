import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { createSlice } from '@reduxjs/toolkit';
import { PBXType } from '../../types/pbx/pbx.type';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import snackbarUtils from '../../utils/SnackbarUtils';
import {
  deletePbxDetails,
  getPbxDetails,
  getPbxFixDialRule,
  getPbxsList,
  patchPbxDetails,
  postPbxDetails,
} from './pbxs.action';

export interface PbxsState {
  pbxsList: null | PaginationType<PBXType>;
  pbxsListFetchStatus: FETCH_STATUS;
  pbxsRouteParams: RouteParamsType;
  pbxDetails: null | PBXType;
  pbxDetailsFetchStatus: FETCH_STATUS;
  pbxDetailsPostStatus: FETCH_STATUS;
  pbxDetailsPatchStatus: FETCH_STATUS;
  pbxDetailsDeleteStatus: FETCH_STATUS;
  pbxFixDialRuleFetchStatus: FETCH_STATUS;
}

export const initialPbxsRouteParams = {
  page: 1,
  limit: 10,
  order: '-pbx.id',
  q: '',
  country_id: '',
  country_category: '',
};

const INIT_STATE: PbxsState = {
  pbxsRouteParams: initialPbxsRouteParams,
  pbxsList: null,
  pbxsListFetchStatus: FETCH_STATUS.NULL,
  pbxDetails: null,
  pbxDetailsFetchStatus: FETCH_STATUS.NULL,
  pbxDetailsPostStatus: FETCH_STATUS.NULL,
  pbxDetailsPatchStatus: FETCH_STATUS.NULL,
  pbxDetailsDeleteStatus: FETCH_STATUS.NULL,
  pbxFixDialRuleFetchStatus: FETCH_STATUS.NULL,
};

const pbxsSlice = createSlice({
  name: 'pbxs',
  initialState: INIT_STATE,
  reducers: {
    setPbxsList: (state, action) => {
      state.pbxsList = action.payload;
    },
    setPbxsListFetchStatus: (state, action) => {
      state.pbxsListFetchStatus = action.payload;
    },
    setPbxDetails: (state, action) => {
      state.pbxDetails = action.payload;
    },
    setPbxDetailsFetchStatus: (state, action) => {
      state.pbxDetailsFetchStatus = action.payload;
    },
    setPbxDetailsPostStatus: (state, action) => {
      state.pbxDetailsPostStatus = action.payload;
    },
    setPbxDetailsPatchStatus: (state, action) => {
      state.pbxDetailsPatchStatus = action.payload;
    },
    setPbxDetailsDeleteStatus: (state, action) => {
      state.pbxDetailsDeleteStatus = action.payload;
    },
    setPbxFixDialRuleFetchStatus: (state, action) => {
      state.pbxFixDialRuleFetchStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'pbxs') {
        state.pbxsRouteParams = { ...state.pbxsRouteParams, ...payload.data };
      }
    });

    // CREATE PBXS DETAILS
    builder.addCase(postPbxDetails.pending, state => {
      state.pbxDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postPbxDetails.rejected, (state, action) => {
      state.pbxDetailsPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postPbxDetails.fulfilled, state => {
      state.pbxDetailsPostStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('PBX created successfully!');
    });

    // GET PBXS LIST
    builder.addCase(getPbxsList.pending, state => {
      state.pbxsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getPbxsList.rejected, (state, action) => {
      state.pbxsListFetchStatus = FETCH_STATUS.REJECTED;
      state.pbxsList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getPbxsList.fulfilled, (state, action) => {
      state.pbxsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.pbxsList = action.payload;
    });

    // GET PBXS DETAILS
    builder.addCase(getPbxDetails.pending, state => {
      state.pbxDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getPbxDetails.rejected, (state, action) => {
      state.pbxDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.pbxDetails = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getPbxDetails.fulfilled, (state, action) => {
      state.pbxDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.pbxDetails = action.payload;
    });

    // PATCH PBXS DETAILS
    builder.addCase(patchPbxDetails.pending, state => {
      state.pbxDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchPbxDetails.rejected, (state, action) => {
      state.pbxDetailsPatchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(patchPbxDetails.fulfilled, state => {
      state.pbxDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('PBX updated successfully!');
    });

    // DELETE PBXS DETAILS
    builder.addCase(deletePbxDetails.pending, state => {
      state.pbxDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deletePbxDetails.rejected, (state, action) => {
      state.pbxDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(deletePbxDetails.fulfilled, state => {
      state.pbxDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('PBX deleted successfully!');
    });

    // GET FIX DIAL RULE
    builder.addCase(getPbxFixDialRule.pending, state => {
      state.pbxDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getPbxFixDialRule.rejected, (state, action) => {
      state.pbxDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getPbxFixDialRule.fulfilled, state => {
      state.pbxDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('PBX fix dial rule has been succeeded!');
    });
  },
});

export const {
  setPbxDetailsDeleteStatus,
  setPbxsListFetchStatus,
  setPbxDetailsFetchStatus,
  setPbxDetailsPatchStatus,
  setPbxDetailsPostStatus,
  setPbxsList,
  setPbxDetails,
  setPbxFixDialRuleFetchStatus,
} = pbxsSlice.actions;

export default pbxsSlice.reducer;
