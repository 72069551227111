import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncTaskType } from '../../types/async-task/async-task.type';
import { RootState } from '../../redux/Store';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { additionalQueryParams } from '../../utils/utilities';
import { setShowModal, setModalContent } from '../utils/utils.slice';
import { TcSimType } from '../../types/tc-sim/tc-sim.type';

export const postTcSims = createAsyncThunk<
  AsyncTaskType,
  {
    data: {
      warehouse: { id: number };
      equipment_group: { id: number };
      tcSims: Array<unknown>;
    };
  },
  { state: RootState }
>('tcSims/postTcSims', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<AsyncTaskType>(`${process.env.REACT_APP_API_URL}/tc-sim/bulk`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating SIMs!');
  }
});

export const postTcSimDetails = createAsyncThunk<TcSimType, { data: unknown }, { state: RootState }>(
  'tcSims/postTcSimDetails',
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post<TcSimType>(`${process.env.REACT_APP_API_URL}/tc-sim`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating SIM!');
    }
  }
);

export const getTcSimsList = createAsyncThunk<PaginationType<TcSimType>, RouteParamsType, { state: RootState }>(
  'tcSims/getTcSimsList',
  async (params, thunkAPI) => {
    try {
      const additionalParams = additionalQueryParams(
        thunkAPI.getState().sharedReducer.selectedCompany,
        thunkAPI.getState().sharedReducer.selectedLocation,
        thunkAPI.getState().sharedReducer.includeSuborgs
      );
      const mergedParams = { ...params, ...additionalParams };
      const queryString = Object.keys(mergedParams)
        .map(key => key + '=' + mergedParams[key])
        .join('&');

      const response = await axios.get<PaginationType<TcSimType>>(
        `${process.env.REACT_APP_API_URL}/tc-sim${queryString ? `?${queryString}` : ''}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching SIM!');
    }
  }
);

export const getTcSimDetails = createAsyncThunk<TcSimType, { id: number }, { state: RootState }>(
  'tcSims/getTcSimDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<TcSimType>(`${process.env.REACT_APP_API_URL}/tc-sim/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching SIM!');
    }
  }
);

export const patchTcSimDetails = createAsyncThunk<
  TcSimType,
  { id: number; data: unknown },
  { state: RootState }
>('tcSims/patchTcSimDetails', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<TcSimType>(`${process.env.REACT_APP_API_URL}/tc-sim/${id}`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating SIM!');
  }
});

export const deleteTcSimDetails = createAsyncThunk<TcSimType, { id: number; fetchList: boolean }, { state: RootState }>(
  'tcSims/deleteTcSimDetails',
  async ({ id, fetchList }, thunkAPI) => {
    try {
      const response = await axios.delete<TcSimType>(`${process.env.REACT_APP_API_URL}/tc-sim/${id}`);
      if (fetchList) thunkAPI.dispatch(getTcSimsList(thunkAPI.getState().tcSimsReducer.tcSimsRouteParams));
      thunkAPI.dispatch(setShowModal(false));
      thunkAPI.dispatch(setModalContent(null));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting SIM!');
    }
  }
);
