import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { createSlice } from '@reduxjs/toolkit';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import {
  deleteSMSsimDetails,
  getFullSMSPlansList,
  getSMSLoggerDeviceNames,
  getSMSLoggerList,
  getSMSLoggerSummary,
  getSMSSIM,
  getSMSSIMsList, patchSMSsimDetails, postSMSsimDetails,
} from './smsLogger.action';
import snackbarUtils from '../../utils/SnackbarUtils';

export interface SmsLoggersState {
  accessToken: string | null;
  smsLoggerList: null | unknown;
  smsLoggerListFetchStatus: FETCH_STATUS;
  smsSIMsList: null | unknown;
  smsSIMsListFetchStatus: FETCH_STATUS;
  smsLoggerSummary: null | unknown;
  smsLoggerSummaryFetchStatus: FETCH_STATUS;
  smsLoggerDeviceNames: null | unknown;
  smsLoggerDeviceNamesFetchStatus: FETCH_STATUS;
  smsFullPlanList: null | unknown;
  smsFullPlanListFetchStatus: FETCH_STATUS;
  smsLoggerRouteParams: RouteParamsType;
  smsSIMsRouteParams: RouteParamsType;
  smsSimDetails: null | unknown;
  smsSimDetailsFetchStatus: FETCH_STATUS;
  smsSimDetailsPatchStatus: FETCH_STATUS;
  smsSimDetailsPostStatus: FETCH_STATUS;
  smsSimDetailsDeleteStatus: FETCH_STATUS;
}

export const smsLoggerInitialRouteParams = {
  page: 1,
  limit: 10,
  order: 'obj.id',
  q: '',
  status: 'all',
  active: 'all',
  hr_test: 'all',
  device: '',
};

export const smsSimsInitialRouteParams = {
  page: 1,
  limit: 10,
  order: 'obj.id',
  q: '',
};

const INIT_STATE: SmsLoggersState = {
  accessToken: null,
  smsLoggerList: null,
  smsLoggerListFetchStatus: FETCH_STATUS.NULL,
  smsLoggerRouteParams: smsLoggerInitialRouteParams,
  smsSIMsList: null,
  smsSIMsListFetchStatus: FETCH_STATUS.NULL,
  smsSIMsRouteParams: smsSimsInitialRouteParams,
  smsLoggerSummary: null,
  smsLoggerSummaryFetchStatus: FETCH_STATUS.NULL,
  smsLoggerDeviceNames: null,
  smsLoggerDeviceNamesFetchStatus: FETCH_STATUS.NULL,
  smsFullPlanList: null,
  smsFullPlanListFetchStatus: FETCH_STATUS.NULL,
  smsSimDetails: null,
  smsSimDetailsFetchStatus: FETCH_STATUS.NULL,
  smsSimDetailsDeleteStatus: FETCH_STATUS.NULL,
  smsSimDetailsPostStatus: FETCH_STATUS.NULL,
  smsSimDetailsPatchStatus: FETCH_STATUS.NULL,
};

const smsLoggersSlice = createSlice({
  name: 'smsLoggers',
  initialState: INIT_STATE,
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setSmsLoggerList: (state, action) => {
      state.smsLoggerList = action.payload;
    },
    setSmsLoggerListFetchStatus: (state, action) => {
      state.smsLoggerListFetchStatus = action.payload;
    },
    setSmsSIMsList: (state, action) => {
      state.smsSIMsList = action.payload;
    },
    setSmsSIMsListFetchStatus: (state, action) => {
      state.smsSIMsListFetchStatus = action.payload;
    },
    setSmsLoggerSummary: (state, action) => {
      state.smsLoggerSummary = action.payload;
    },
    setSmsLoggerSummaryFetchStatus: (state, action) => {
      state.smsLoggerSummaryFetchStatus = action.payload;
    },
    setSmsLoggerDeviceNames: (state, action) => {
      state.smsLoggerDeviceNames = action.payload;
    },
    setSmsLoggerDeviceNamesFetchStatus: (state, action) => {
      state.smsLoggerDeviceNamesFetchStatus = action.payload;
    },
    setSmsFullPlanList: (state, action) => {
      state.smsFullPlanList = action.payload;
    },
    setSmsFullPlanListFetchStatus: (state, action) => {
      state.smsFullPlanListFetchStatus = action.payload;
    },
    setSmsSimDetails: (state, action) => {
      state.smsSimDetails = action.payload;
    },
    setSmsSimDetailsFetchStatus: (state, action) => {
      state.smsSimDetailsFetchStatus = action.payload;
    },
    setSmsSimDetailsDeleteStatus: (state, action) => {
      state.smsSimDetailsDeleteStatus = action.payload;
    },
    setSmsSimDetailsPostStatus: (state, action) => {
      state.smsSimDetailsPostStatus = action.payload;
    },
    setSmsSimDetailsPatchStatus: (state, action) => {
      state.smsSimDetailsPatchStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'smsLogger') {
        state.smsLoggerRouteParams = { ...state.smsLoggerRouteParams, ...payload.data };
      }

      if (payload.reducer === 'smsSims') {
        state.smsSIMsRouteParams = { ...state.smsSIMsRouteParams, ...payload.data };
      }
    });

    // GET SMS LOGGER LIST
    builder.addCase(getSMSLoggerList.pending, state => {
      state.smsLoggerListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getSMSLoggerList.rejected, (state, action) => {
      state.smsLoggerListFetchStatus = FETCH_STATUS.REJECTED;
      state.smsLoggerList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getSMSLoggerList.fulfilled, (state, action) => {
      state.smsLoggerListFetchStatus = FETCH_STATUS.FULFILLED;
      state.smsLoggerList = action.payload;
    });

    // GET SMS LOGGER SIMS LIST
    builder.addCase(getSMSSIMsList.pending, state => {
      state.smsSIMsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getSMSSIMsList.rejected, (state, action) => {
      state.smsSIMsListFetchStatus = FETCH_STATUS.REJECTED;
      state.smsSIMsList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getSMSSIMsList.fulfilled, (state, action) => {
      state.smsSIMsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.smsSIMsList = action.payload;
    });

    // GET SMS LOGGER SIMS LIST
    builder.addCase(getSMSLoggerSummary.pending, state => {
      state.smsLoggerSummaryFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getSMSLoggerSummary.rejected, (state, action) => {
      state.smsLoggerSummaryFetchStatus = FETCH_STATUS.REJECTED;
      state.smsLoggerSummary = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getSMSLoggerSummary.fulfilled, (state, action) => {
      state.smsLoggerSummaryFetchStatus = FETCH_STATUS.FULFILLED;
      state.smsLoggerSummary = action.payload;
    });

    // GET SMS LOGGER SIMS LIST
    builder.addCase(getSMSLoggerDeviceNames.pending, state => {
      state.smsLoggerDeviceNamesFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getSMSLoggerDeviceNames.rejected, (state, action) => {
      state.smsLoggerDeviceNamesFetchStatus = FETCH_STATUS.REJECTED;
      state.smsLoggerDeviceNames = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getSMSLoggerDeviceNames.fulfilled, (state, action) => {
      state.smsLoggerDeviceNamesFetchStatus = FETCH_STATUS.FULFILLED;
      state.smsLoggerDeviceNames = action.payload;
    });

    // GET SMS LOGGER SIMS LIST
    builder.addCase(getSMSSIM.pending, state => {
      state.smsSimDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getSMSSIM.rejected, (state, action) => {
      state.smsSimDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.smsSimDetails = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getSMSSIM.fulfilled, (state, action) => {
      state.smsSimDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.smsSimDetails = action.payload;
    });

    // GET SMS LOGGER SIMS LIST
    builder.addCase(getFullSMSPlansList.pending, state => {
      state.smsFullPlanListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullSMSPlansList.rejected, (state, action) => {
      state.smsFullPlanListFetchStatus = FETCH_STATUS.REJECTED;
      state.smsFullPlanList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getFullSMSPlansList.fulfilled, (state, action) => {
      state.smsFullPlanListFetchStatus = FETCH_STATUS.FULFILLED;
      state.smsFullPlanList = action.payload;
    });

    // GET SMS LOGGER SIMS LIST
    builder.addCase(deleteSMSsimDetails.pending, state => {
      state.smsSimDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteSMSsimDetails.rejected, (state, action) => {
      state.smsSimDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(deleteSMSsimDetails.fulfilled, (state, action) => {
      state.smsSimDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
    });

    // GET SMS LOGGER SIMS LIST
    builder.addCase(postSMSsimDetails.pending, state => {
      state.smsSimDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postSMSsimDetails.rejected, (state, action) => {
      state.smsSimDetailsPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postSMSsimDetails.fulfilled, (state, action) => {
      state.smsSimDetailsPostStatus = FETCH_STATUS.FULFILLED;
    });

    // GET SMS LOGGER SIMS LIST
    builder.addCase(patchSMSsimDetails.pending, state => {
      state.smsSimDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchSMSsimDetails.rejected, (state, action) => {
      state.smsSimDetailsPatchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(patchSMSsimDetails.fulfilled, (state, action) => {
      state.smsSimDetailsPatchStatus = FETCH_STATUS.FULFILLED;
    });
  },
});

export const {
  setAccessToken,
  setSmsFullPlanListFetchStatus,
  setSmsLoggerListFetchStatus,
  setSmsLoggerDeviceNamesFetchStatus,
  setSmsLoggerDeviceNames,
  setSmsSimDetailsFetchStatus,
  setSmsSIMsListFetchStatus,
  setSmsLoggerSummaryFetchStatus,
  setSmsLoggerList,
  setSmsLoggerSummary,
  setSmsSimDetails,
  setSmsSIMsList,
  setSmsFullPlanList,
  setSmsSimDetailsPatchStatus,
  setSmsSimDetailsPostStatus,
  setSmsSimDetailsDeleteStatus,
} = smsLoggersSlice.actions;

export default smsLoggersSlice.reducer;
