import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncTaskType } from '../../types/async-task/async-task.type';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { additionalQueryParams } from '../../utils/utilities';
import { setShowModal, setModalContent } from '../../features/utils/utils.slice';
import {
  CreatePowerCycleDto,
  PowerCycleDetailsType,
  PowerCycleListItemType,
  UpdatePowerCycleDto,
} from '../../types/power-cycle/power-cycle.type';
import { UpdatePowerCycleChannelsDto } from '../../types/power-cycle/power-cycle-channel/power-cycle-channel.type';
import { GrandstreamATAType } from '../../types/ata/grandstream-ata/grandstream-ata.type';
import { ACSATAType } from '../../types/ata/acs-ata/acs-ata.type';
import { MicroserviceRouterType } from '../../types/router/router.type';
import { DialerType } from '../../types/dialer/dialer.type';
import { POWER_CYCLE_DEVICE_TYPE } from '../../utils/constants';
import { PowerCycleTypeType } from '../../types/power-cycle/power-cycle-type/power-cycle-type.type';
import { BatteryType } from '../../types/battery/battery.type';

export const postPowerCycles = createAsyncThunk<
  AsyncTaskType,
  {
    data: {
      warehouse: { id: number };
      equipment_group: { id: number };
      powerCycles: Array<{
        sn: string;
        password: string;
        is_inventory: boolean;
        warehouse_location?: { rack: string; shelf: string };
      }>;
    };
  },
  { state: RootState }
>('powerCycles/postPowerCycles', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<AsyncTaskType>(`${process.env.REACT_APP_API_URL}/power-cycles/bulk`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating powerCycles!');
  }
});

export const postPowerCycleDetails = createAsyncThunk<
  PowerCycleDetailsType,
  { data: CreatePowerCycleDto },
  { state: RootState }
>('powerCycles/postPowerCycleDetails', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<PowerCycleDetailsType>(`${process.env.REACT_APP_API_URL}/power-cycles`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating powerCycle!');
  }
});

export const getPowerCyclesList = createAsyncThunk<
  PaginationType<PowerCycleListItemType>,
  RouteParamsType,
  { state: RootState }
>('powerCycles/getPowerCyclesList', async (params, thunkAPI) => {
  try {
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      thunkAPI.getState().sharedReducer.selectedLocation,
      thunkAPI.getState().sharedReducer.includeSuborgs
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<PowerCycleListItemType>>(
      `${process.env.REACT_APP_API_URL}/power-cycles${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching powerCycles!');
  }
});

export const getPowerCycleDetails = createAsyncThunk<PowerCycleDetailsType, { id: number }, { state: RootState }>(
  'powerCycles/getPowerCycleDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<PowerCycleDetailsType>(`${process.env.REACT_APP_API_URL}/power-cycles/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching powerCycle!');
    }
  }
);

export const patchPowerCycleDetails = createAsyncThunk<
  PowerCycleDetailsType,
  { id: number; data: UpdatePowerCycleDto },
  { state: RootState }
>('powerCycles/patchPowerCycleDetails', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<PowerCycleDetailsType>(
      `${process.env.REACT_APP_API_URL}/power-cycles/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating powerCycle!');
  }
});

export const patchPowerCycleChannels = createAsyncThunk<
  { success: boolean },
  { id: number; data: UpdatePowerCycleChannelsDto },
  { state: RootState }
>('powerCycles/patchPowerCycleChannels', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<{ success: boolean }>(
      `${process.env.REACT_APP_API_URL}/power-cycle-channels/${id}`,
      data
    );
    thunkAPI.dispatch(getPowerCycleDetails({ id }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating powerCycle channels!');
  }
});

export const deletePowerCycleDetails = createAsyncThunk<
  PowerCycleDetailsType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('powerCycles/deletePowerCycleDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<PowerCycleDetailsType>(`${process.env.REACT_APP_API_URL}/power-cycles/${id}`);
    if (fetchList) thunkAPI.dispatch(getPowerCyclesList(thunkAPI.getState().powerCyclesReducer.powerCyclesRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting powerCycle!');
  }
});

export const getPowerCycleDevicesList = createAsyncThunk<
  {
    atas: Array<{ id: string; name: string }>;
    acs_atas: Array<{ id: string; name: string }>;
    routers: Array<{ id: string; name: string }>;
    dialers: Array<{ id: string; name: string }>;
    batteries: Array<{ id: string; name: string }>;
  },
  { hash: string; id: number },
  { state: RootState }
>('powerCycles/getPowerCycleDevicesList', async ({ hash, id }, thunkAPI) => {
  try {
    const response = await axios.get<{
      atas: Array<GrandstreamATAType>;
      acs_atas: Array<ACSATAType>;
      routers: Array<MicroserviceRouterType>;
      dialers: Array<DialerType>;
      batteries: Array<BatteryType>;
    }>(`${process.env.REACT_APP_API_URL}/equipment-group/devices/${hash}?power_cycle_id=${id}`);

    let dialers = response.data.dialers;
    if (dialers.length > 0) {
      dialers = dialers?.filter(dialer => {
        const name = dialer?.dialer_type?.name || '';
        return name.toUpperCase().includes('IP');
      });
    }

    return {
      atas:
        response.data?.atas?.map(ata => ({
          id: `${POWER_CYCLE_DEVICE_TYPE.ATA}_${ata?.id}`,
          name: ata?.serial_number || '',
        })) || [],
      acs_atas:
        response.data?.acs_atas?.map(acs_ata => ({
          id: `${POWER_CYCLE_DEVICE_TYPE.ACS_ATA}_${acs_ata?.id}`,
          name: acs_ata?.serial_number || '',
        })) || [],
      routers:
        response.data?.routers?.map(router => ({
          id: `${POWER_CYCLE_DEVICE_TYPE.ROUTER}_${router?.id}`,
          name: router?.serial_number || '',
        })) || [],
      dialers:
        dialers.map(dialer => ({
          id: `${POWER_CYCLE_DEVICE_TYPE.DIALER}_${dialer?.id}`,
          name: dialer?.radio_id || '',
        })) || [],
      batteries: response.data.batteries.map(battery => ({
        id: `${battery?.id}`,
        name: battery?.serial || '',
      })),
    };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching powerCycle devices!');
  }
});

export const postPowerCycleChannelCommand = createAsyncThunk<
  PowerCycleDetailsType,
  { id: number; powerCycleID: number },
  { state: RootState }
>('powerCycles/postPowerCycleChannelCommand', async ({ id, powerCycleID }, thunkAPI) => {
  try {
    const response = await axios.post<PowerCycleDetailsType>(
      `${process.env.REACT_APP_API_URL}/power-cycle-channels/${id}/send-command`,
      undefined
    );

    thunkAPI.dispatch(getPowerCycleDetails({ id: powerCycleID }));

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during sending powerCycle channel command!');
  }
});

export const postPowerCycleStatus = createAsyncThunk<PowerCycleDetailsType, { id: number }, { state: RootState }>(
  'powerCycles/postPowerCycleStatus',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.post<PowerCycleDetailsType>(
        `${process.env.REACT_APP_API_URL}/power-cycles/${id}/update-status`,
        undefined
      );
      thunkAPI.dispatch(getPowerCycleDetails({ id }));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during refreshing powerCycle status!');
    }
  }
);

export const getPowerCycleTypesList = createAsyncThunk<
  Array<Pick<PowerCycleTypeType, 'name' | 'id' | 'numberOfChannels'>>,
  void,
  { state: RootState }
>('powerCycles/getPowerCycleTypesList', async (_, thunkAPI) => {
  try {
    const response = await axios.get<Array<Pick<PowerCycleTypeType, 'name' | 'id' | 'numberOfChannels'>>>(
      `${process.env.REACT_APP_API_URL}/power-cycle-type`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching powerCycles types!');
  }
});
