import { RouteParamsType } from '../../types/utils/router-params.type';
import { createSlice } from '@reduxjs/toolkit';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { LocationDetailsType, LocationListItemType } from '../../types/location/location.type';
import snackbarUtils from '../../utils/SnackbarUtils';
import {
  deleteLocationDetails,
  getImageURL,
  getLocationConnectivityList,
  getLocationDetails,
  getLocationEquipmentList,
  getLocationOrdersList,
  getLocationServicesList,
  getLocationsList,
  getLocationTelcoList,
  getLocationTicketsList,
  getPublicLocationDetails,
  patchLocationDetails,
  patchLocationInfoPanelDetails,
  postLocationDetails,
  postLocationInfoPanelDetails,
  postLocations,
} from './locations.action';
import { PBXListItemType } from '../../types/pbx/PBXListItem.type';
import { DIDsListItemType } from '../../types/did/did.type';
import { PbxExtensionListItemType } from '../../types/pbx-extension/pbx-extension.type';

export interface LocationsState {
  locationsList: null | PaginationType<LocationListItemType>;
  locationsListFetchStatus: FETCH_STATUS;
  locationsRouteParams: RouteParamsType;
  locationDetails: null | LocationDetailsType;
  locationDetailsFetchStatus: FETCH_STATUS;
  locationDetailsPostStatus: FETCH_STATUS;
  locationDetailsPatchStatus: FETCH_STATUS;
  locationDetailsDeleteStatus: FETCH_STATUS;
  locationsPostStatus: FETCH_STATUS;
  locationsBulk: null | Array<{
    address1: string;
    address2: string;
    address3: string;
    city: string;
    'country (ALPHA-2)': string;
    customer_id: string;
    e911_notify_email: string;
    name: string;
    postal: string;
    state: string;
    zip_code: string;
  }>;
  locationTelcoList: null | {
    pbxs: Array<PBXListItemType>;
    dids: Array<DIDsListItemType>;
    pbxExtensions: Array<PbxExtensionListItemType>;
  };
  locationTelcoListFetchStatus: FETCH_STATUS;
  locationServicesList: null | Array<any>;
  locationServicesListFetchStatus: FETCH_STATUS;
  locationEquipmentList: null | Array<any>;
  locationEquipmentListFetchStatus: FETCH_STATUS;
  locationConnectivityList: null | Array<any>;
  locationConnectivityListFetchStatus: FETCH_STATUS;
  locationTicketsList: null | Array<any>;
  locationTicketsListFetchStatus: FETCH_STATUS;
  locationOrdersList: null | Array<any>;
  locationOrdersListFetchStatus: FETCH_STATUS;
  imageURL: null | string;
  imageURLFetchStatus: FETCH_STATUS;
  locationInfoPanelDetailsPostStatus: FETCH_STATUS;
  locationInfoPanelDetailsPatchStatus: FETCH_STATUS;
  validationAddressPostStatus: FETCH_STATUS;
  publicLocationDetails: null | LocationDetailsType;
  publicLocationDetailsFetchStatus: FETCH_STATUS;
}

export const initialLocationsRouteParams = {
  page: 1,
  limit: 10,
  order: 'location.name',
  q: '',
  country_id: '',
  country_category: '',
};

const INIT_STATE: LocationsState = {
  locationsList: null,
  locationsListFetchStatus: FETCH_STATUS.NULL,
  locationsRouteParams: initialLocationsRouteParams,
  locationDetails: null,
  locationDetailsFetchStatus: FETCH_STATUS.NULL,
  locationDetailsPostStatus: FETCH_STATUS.NULL,
  locationDetailsPatchStatus: FETCH_STATUS.NULL,
  locationDetailsDeleteStatus: FETCH_STATUS.NULL,
  locationsPostStatus: FETCH_STATUS.NULL,
  locationsBulk: null,
  locationTelcoList: null,
  locationTelcoListFetchStatus: FETCH_STATUS.NULL,
  locationServicesList: null,
  locationServicesListFetchStatus: FETCH_STATUS.NULL,
  locationEquipmentList: null,
  locationEquipmentListFetchStatus: FETCH_STATUS.NULL,
  locationConnectivityList: null,
  locationConnectivityListFetchStatus: FETCH_STATUS.NULL,
  locationTicketsList: null,
  locationTicketsListFetchStatus: FETCH_STATUS.NULL,
  locationOrdersList: null,
  locationOrdersListFetchStatus: FETCH_STATUS.NULL,
  imageURL: null,
  imageURLFetchStatus: FETCH_STATUS.NULL,
  locationInfoPanelDetailsPostStatus: FETCH_STATUS.NULL,
  locationInfoPanelDetailsPatchStatus: FETCH_STATUS.NULL,
  validationAddressPostStatus: FETCH_STATUS.NULL,
  publicLocationDetails: null,
  publicLocationDetailsFetchStatus: FETCH_STATUS.NULL,
};

export const locationsSlice = createSlice({
  name: 'locations',
  initialState: INIT_STATE,
  reducers: {
    setLocationsList: (state, action) => {
      state.locationsList = action.payload;
    },
    setLocationsListFetchStatus: (state, action) => {
      state.locationsListFetchStatus = action.payload;
    },
    setLocationDetails: (state, action) => {
      state.locationDetails = action.payload;
    },
    setLocationDetailsFetchStatus: (state, action) => {
      state.locationDetailsFetchStatus = action.payload;
    },
    setLocationDetailsPostStatus: (state, action) => {
      state.locationDetailsPostStatus = action.payload;
    },
    setLocationDetailsPatchStatus: (state, action) => {
      state.locationDetailsPatchStatus = action.payload;
    },
    setLocationDetailsDeleteStatus: (state, action) => {
      state.locationDetailsDeleteStatus = action.payload;
    },
    setLocationsBulk: (state, action) => {
      state.locationsBulk = action.payload;
    },
    setLocationsPostStatus: (state, action) => {
      state.locationsPostStatus = action.payload;
    },
    setLocationTelcoList: (state, action) => {
      state.locationTelcoList = action.payload;
    },
    setLocationTelcoListFetchStatus: (state, action) => {
      state.locationTelcoListFetchStatus = action.payload;
    },
    setLocationServicesList: (state, action) => {
      state.locationServicesList = action.payload;
    },
    setLocationServicesListFetchStatus: (state, action) => {
      state.locationServicesListFetchStatus = action.payload;
    },
    setLocationEquipmentList: (state, action) => {
      state.locationEquipmentList = action.payload;
    },
    setLocationEquipmentListFetchStatus: (state, action) => {
      state.locationEquipmentListFetchStatus = action.payload;
    },
    setLocationConnectivityList: (state, action) => {
      state.locationConnectivityList = action.payload;
    },
    setLocationConnectivityListFetchStatus: (state, action) => {
      state.locationConnectivityListFetchStatus = action.payload;
    },
    setLocationTicketsList: (state, action) => {
      state.locationTicketsList = action.payload;
    },
    setLocationTicketsListFetchStatus: (state, action) => {
      state.locationTicketsListFetchStatus = action.payload;
    },
    setLocationOrdersList: (state, action) => {
      state.locationOrdersList = action.payload;
    },
    setLocationOrdersListFetchStatus: (state, action) => {
      state.locationOrdersListFetchStatus = action.payload;
    },
    setImageURL: (state, action) => {
      state.imageURL = action.payload;
    },
    setImageURLFetchStatus: (state, action) => {
      state.imageURLFetchStatus = action.payload;
    },
    setLocationInfoPanelDetailsPostStatus: (state, action) => {
      state.locationInfoPanelDetailsPostStatus = action.payload;
    },
    setLocationInfoPanelDetailsPatchStatus: (state, action) => {
      state.locationInfoPanelDetailsPatchStatus = action.payload;
    },
    setValidationAddressPostStatus: (state, action) => {
      state.validationAddressPostStatus = action.payload;
    },
    setPublicLocationDetails: (state, action) => {
      state.publicLocationDetails = action.payload;
    },
    setPublicLocationDetailsFetchStatus: (state, action) => {
      state.publicLocationDetailsFetchStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'locations') {
        state.locationsRouteParams = { ...state.locationsRouteParams, ...payload.data };
      }
    });

    // CREATE LOCATIONS DETAILS
    builder.addCase(postLocations.pending, state => {
      state.locationsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postLocations.rejected, (state, action) => {
      state.locationsPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postLocations.fulfilled, state => {
      state.locationsPostStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Locations created successfully');
    });

    // CREATE LOCATIONS DETAILS
    builder.addCase(postLocationDetails.pending, state => {
      state.locationDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postLocationDetails.rejected, (state, action) => {
      state.locationDetailsPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postLocationDetails.fulfilled, state => {
      state.locationDetailsPostStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Location created successfully');
    });

    // GET LOCATIONS LIST
    builder.addCase(getLocationsList.pending, state => {
      state.locationsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getLocationsList.rejected, (state, action) => {
      state.locationsListFetchStatus = FETCH_STATUS.REJECTED;
      state.locationsList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getLocationsList.fulfilled, (state, action) => {
      state.locationsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.locationsList = action.payload;
    });

    // GET LOCATIONS DETAILS
    builder.addCase(getLocationDetails.pending, state => {
      state.locationDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getLocationDetails.rejected, (state, action) => {
      state.locationDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.locationDetails = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getLocationDetails.fulfilled, (state, action) => {
      state.locationDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.locationDetails = action.payload;
    });

    // PATCH LOCATIONS DETAILS
    builder.addCase(patchLocationDetails.pending, state => {
      state.locationDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchLocationDetails.rejected, (state, action) => {
      state.locationDetailsPatchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(patchLocationDetails.fulfilled, state => {
      state.locationDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Location updated successfully');
    });

    // DELETE LOCATIONS DETAILS
    builder.addCase(deleteLocationDetails.pending, state => {
      state.locationDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteLocationDetails.rejected, (state, action) => {
      state.locationDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(deleteLocationDetails.fulfilled, state => {
      state.locationDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Location deleted successfully');
    });

    // GET LOCATIONS CONNECTIVITY LIST
    builder.addCase(getLocationConnectivityList.pending, state => {
      state.locationConnectivityListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getLocationConnectivityList.rejected, (state, action) => {
      state.locationConnectivityListFetchStatus = FETCH_STATUS.REJECTED;
      state.locationConnectivityList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getLocationConnectivityList.fulfilled, (state, action) => {
      state.locationConnectivityListFetchStatus = FETCH_STATUS.FULFILLED;
      state.locationConnectivityList = action.payload;
    });

    // GET LOCATIONS EQUIPMENT LIST
    builder.addCase(getLocationEquipmentList.pending, state => {
      state.locationEquipmentListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getLocationEquipmentList.rejected, (state, action) => {
      state.locationEquipmentListFetchStatus = FETCH_STATUS.REJECTED;
      state.locationEquipmentList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getLocationEquipmentList.fulfilled, (state, action) => {
      state.locationEquipmentListFetchStatus = FETCH_STATUS.FULFILLED;
      state.locationEquipmentList = action.payload;
    });

    // GET LOCATIONS SERVICES LIST
    builder.addCase(getLocationServicesList.pending, state => {
      state.locationServicesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getLocationServicesList.rejected, (state, action) => {
      state.locationServicesListFetchStatus = FETCH_STATUS.REJECTED;
      state.locationServicesList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getLocationServicesList.fulfilled, (state, action) => {
      state.locationServicesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.locationServicesList = action.payload;
    });

    // GET LOCATIONS TELCO LIST
    builder.addCase(getLocationTelcoList.pending, state => {
      state.locationTelcoListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getLocationTelcoList.rejected, (state, action) => {
      state.locationTelcoListFetchStatus = FETCH_STATUS.REJECTED;
      state.locationTelcoList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getLocationTelcoList.fulfilled, (state, action) => {
      state.locationTelcoListFetchStatus = FETCH_STATUS.FULFILLED;
      state.locationTelcoList = action.payload;
    });

    // GET LOCATIONS TICKETS LIST
    builder.addCase(getLocationTicketsList.pending, state => {
      state.locationTicketsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getLocationTicketsList.rejected, (state, action) => {
      state.locationTicketsListFetchStatus = FETCH_STATUS.REJECTED;
      state.locationTicketsList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getLocationTicketsList.fulfilled, (state, action) => {
      state.locationTicketsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.locationTicketsList = action.payload;
    });

    // GET LOCATIONS ORDERS LIST
    builder.addCase(getLocationOrdersList.pending, state => {
      state.locationOrdersListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getLocationOrdersList.rejected, (state, action) => {
      state.locationOrdersListFetchStatus = FETCH_STATUS.REJECTED;
      state.locationOrdersList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getLocationOrdersList.fulfilled, (state, action) => {
      state.locationOrdersListFetchStatus = FETCH_STATUS.FULFILLED;
      state.locationOrdersList = action.payload;
    });

    // GET IMAGE URL
    builder.addCase(getImageURL.pending, state => {
      state.imageURLFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getImageURL.rejected, (state, action) => {
      state.imageURLFetchStatus = FETCH_STATUS.REJECTED;
      state.imageURL = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getImageURL.fulfilled, (state, action) => {
      state.imageURLFetchStatus = FETCH_STATUS.FULFILLED;
      state.imageURL = action.payload;
    });

    // POST LOCATION INFO PANEL DETAILS
    builder.addCase(postLocationInfoPanelDetails.pending, state => {
      state.locationInfoPanelDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postLocationInfoPanelDetails.rejected, (state, action) => {
      state.locationInfoPanelDetailsPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postLocationInfoPanelDetails.fulfilled, state => {
      state.locationInfoPanelDetailsPostStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Location info panel details updated successfully');
    });

    // PATCH LOCATION INFO PANEL DETAILS
    builder.addCase(patchLocationInfoPanelDetails.pending, state => {
      state.locationInfoPanelDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchLocationInfoPanelDetails.rejected, (state, action) => {
      state.locationInfoPanelDetailsPatchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(patchLocationInfoPanelDetails.fulfilled, state => {
      state.locationInfoPanelDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Location info panel details updated successfully');
    });

    // GET PUBLIC LOCATION DETAILS
    builder.addCase(getPublicLocationDetails.pending, state => {
      state.publicLocationDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getPublicLocationDetails.rejected, (state, action) => {
      state.publicLocationDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.publicLocationDetails = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getPublicLocationDetails.fulfilled, (state, action) => {
      state.publicLocationDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.publicLocationDetails = action.payload;
    });
  },
});

export const {
  setLocationDetailsDeleteStatus,
  setLocationDetailsFetchStatus,
  setLocationDetailsPatchStatus,
  setLocationDetailsPostStatus,
  setLocationsListFetchStatus,
  setLocationDetails,
  setLocationsBulk,
  setLocationsPostStatus,
  setLocationsList,
  setLocationConnectivityList,
  setLocationConnectivityListFetchStatus,
  setLocationEquipmentList,
  setLocationEquipmentListFetchStatus,
  setLocationInfoPanelDetailsPatchStatus,
  setLocationInfoPanelDetailsPostStatus,
  setImageURLFetchStatus,
  setLocationOrdersListFetchStatus,
  setLocationServicesList,
  setLocationServicesListFetchStatus,
  setLocationTelcoListFetchStatus,
  setLocationOrdersList,
  setLocationTicketsList,
  setLocationTicketsListFetchStatus,
  setImageURL,
  setLocationTelcoList,
  setValidationAddressPostStatus,
  setPublicLocationDetails,
  setPublicLocationDetailsFetchStatus,
} = locationsSlice.actions;

export default locationsSlice.reducer;
