import { createSlice } from '@reduxjs/toolkit';
import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import snackbarUtils from '../../utils/SnackbarUtils';
import { PbxExtensionDetailsType, PbxExtensionListItemType } from '../../types/pbx-extension/pbx-extension.type';
import {
  deletePbxExtensionDetails,
  getFullPbxExtensionTypesList,
  getPbxExtensionDetails,
  getPbxExtensionsList,
  getPbxExtensionTypesList,
  patchPbxExtensionDetails,
  postPBXExtensionDetails,
  postPbxExtensionOrganizationType,
} from './pbxExtensions.action';
import { PbxExtensionTypeType } from '../../types/pbx-extension-type/pbx-extension-type.type';

export interface PbxExtensionsState {
  pbxExtensionsList: null | PaginationType<PbxExtensionListItemType>;
  pbxExtensionsListFetchStatus: FETCH_STATUS;
  pbxExtensionTypesList: null | Array<PbxExtensionTypeType>;
  pbxExtensionTypesListFetchStatus: FETCH_STATUS;
  fullPbxExtensionTypesList: null | Array<PbxExtensionTypeType>;
  fullPbxExtensionTypesListFetchStatus: FETCH_STATUS;
  pbxExtensionOrganizationTypePostStatus: FETCH_STATUS;
  pbxExtensionsRouteParams: RouteParamsType;
  pbxExtensionDetails: null | PbxExtensionDetailsType;
  pbxExtensionDetailsFetchStatus: FETCH_STATUS;
  pbxExtensionDetailsPatchStatus: FETCH_STATUS;
  pbxExtensionDetailsDeleteStatus: FETCH_STATUS;
  pbxExtensionDetailsPostStatus: FETCH_STATUS;
}

export const initialPbxExtensionsRouteParams = {
  page: 1,
  limit: 10,
  order: 'pbx_extension.id',
  q: '',
};

const INIT_STATE: PbxExtensionsState = {
  pbxExtensionsRouteParams: initialPbxExtensionsRouteParams,
  pbxExtensionsList: null,
  pbxExtensionsListFetchStatus: FETCH_STATUS.NULL,
  pbxExtensionTypesList: null,
  fullPbxExtensionTypesList: null,
  fullPbxExtensionTypesListFetchStatus: FETCH_STATUS.NULL,
  pbxExtensionTypesListFetchStatus: FETCH_STATUS.NULL,
  pbxExtensionOrganizationTypePostStatus: FETCH_STATUS.NULL,
  pbxExtensionDetails: null,
  pbxExtensionDetailsFetchStatus: FETCH_STATUS.NULL,
  pbxExtensionDetailsPatchStatus: FETCH_STATUS.NULL,
  pbxExtensionDetailsDeleteStatus: FETCH_STATUS.NULL,
  pbxExtensionDetailsPostStatus: FETCH_STATUS.NULL,
};

const pbxExtensionsSlice = createSlice({
  name: 'pbxExtensions',
  initialState: INIT_STATE,
  reducers: {
    setPbxExtensionsList: (state, action) => {
      state.pbxExtensionsList = action.payload;
    },
    setPbxExtensionsListFetchStatus: (state, action) => {
      state.pbxExtensionsListFetchStatus = action.payload;
    },
    setPbxExtensionTypeList: (state, action) => {
      state.pbxExtensionTypesList = action.payload;
    },
    setPbxExtensionTypeListFetchStatus: (state, action) => {
      state.pbxExtensionTypesListFetchStatus = action.payload;
    },
    setFullPbxExtensionTypeList: (state, action) => {
      state.fullPbxExtensionTypesList = action.payload;
    },
    setFullPbxExtensionTypeListFetchStatus: (state, action) => {
      state.fullPbxExtensionTypesListFetchStatus = action.payload;
    },
    setPbxExtensionDetails: (state, action) => {
      state.pbxExtensionDetails = action.payload;
    },
    setPbxExtensionDetailsFetchStatus: (state, action) => {
      state.pbxExtensionDetailsFetchStatus = action.payload;
    },
    setPbxExtensionDetailsPatchStatus: (state, action) => {
      state.pbxExtensionDetailsPatchStatus = action.payload;
    },
    setPbxExtensionDetailsDeleteStatus: (state, action) => {
      state.pbxExtensionDetailsDeleteStatus = action.payload;
    },
    setPbxExtensionOrganizationTypePostStatus: (state, action) => {
      state.pbxExtensionOrganizationTypePostStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'pbxExtensions') {
        state.pbxExtensionsRouteParams = { ...state.pbxExtensionsRouteParams, ...payload.data };
      }
    });

    // CREATE PBX EXTENSIONS DETAILS
    builder.addCase(postPBXExtensionDetails.pending, state => {
      state.pbxExtensionDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postPBXExtensionDetails.rejected, (state, action) => {
      state.pbxExtensionDetailsPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postPBXExtensionDetails.fulfilled, state => {
      state.pbxExtensionDetailsPostStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('PBX Extension created successfully');
    });

    // GET PBX EXTENSIONS LIST
    builder.addCase(getPbxExtensionsList.pending, state => {
      state.pbxExtensionsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getPbxExtensionsList.rejected, (state, action) => {
      state.pbxExtensionsListFetchStatus = FETCH_STATUS.REJECTED;
      state.pbxExtensionsList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getPbxExtensionsList.fulfilled, (state, action) => {
      state.pbxExtensionsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.pbxExtensionsList = action.payload;
    });

    // GET PBX EXTENSIONS DETAILS
    builder.addCase(getPbxExtensionDetails.pending, state => {
      state.pbxExtensionDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getPbxExtensionDetails.rejected, (state, action) => {
      state.pbxExtensionDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.pbxExtensionDetails = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getPbxExtensionDetails.fulfilled, (state, action) => {
      state.pbxExtensionDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.pbxExtensionDetails = action.payload;
    });

    // PATCH PBX EXTENSIONS DETAILS
    builder.addCase(patchPbxExtensionDetails.pending, state => {
      state.pbxExtensionDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchPbxExtensionDetails.rejected, (state, action) => {
      state.pbxExtensionDetailsPatchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(patchPbxExtensionDetails.fulfilled, state => {
      state.pbxExtensionDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('PbxExtensions updated successfully');
    });

    // DELETE PBX EXTENSIONS DETAILS
    builder.addCase(deletePbxExtensionDetails.pending, state => {
      state.pbxExtensionDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deletePbxExtensionDetails.rejected, (state, action) => {
      state.pbxExtensionDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(deletePbxExtensionDetails.fulfilled, state => {
      state.pbxExtensionDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('PbxExtensions deleted successfully');
    });

    // GET PBX EXTENSION TYPES LIST
    builder.addCase(getPbxExtensionTypesList.pending, state => {
      state.pbxExtensionTypesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getPbxExtensionTypesList.rejected, (state, action) => {
      state.pbxExtensionTypesListFetchStatus = FETCH_STATUS.REJECTED;
      state.pbxExtensionTypesList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getPbxExtensionTypesList.fulfilled, (state, action) => {
      state.pbxExtensionTypesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.pbxExtensionTypesList = action.payload;
    });

    // GET FULL PBX EXTENSION TYPES LIST
    builder.addCase(getFullPbxExtensionTypesList.pending, state => {
      state.fullPbxExtensionTypesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getFullPbxExtensionTypesList.rejected, (state, action) => {
      state.fullPbxExtensionTypesListFetchStatus = FETCH_STATUS.REJECTED;
      state.fullPbxExtensionTypesList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getFullPbxExtensionTypesList.fulfilled, (state, action) => {
      state.fullPbxExtensionTypesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.fullPbxExtensionTypesList = action.payload;
    });

    // POST PBX EXTENSION ORGANIZATION TYPE
    builder.addCase(postPbxExtensionOrganizationType.pending, state => {
      state.pbxExtensionOrganizationTypePostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postPbxExtensionOrganizationType.rejected, (state, action) => {
      state.pbxExtensionOrganizationTypePostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postPbxExtensionOrganizationType.fulfilled, state => {
      state.pbxExtensionOrganizationTypePostStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Organization PBX Extensions updated successfully');
    });
  },
});

export const {
  setPbxExtensionDetailsDeleteStatus,
  setPbxExtensionDetailsFetchStatus,
  setPbxExtensionDetailsPatchStatus,
  setPbxExtensionsListFetchStatus,
  setPbxExtensionsList,
  setPbxExtensionDetails,
  setPbxExtensionTypeListFetchStatus,
  setPbxExtensionTypeList,
  setFullPbxExtensionTypeListFetchStatus,
  setFullPbxExtensionTypeList,
  setPbxExtensionOrganizationTypePostStatus,
} = pbxExtensionsSlice.actions;

export default pbxExtensionsSlice.reducer;
