import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserType } from '../../types/user/user.type';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { additionalQueryParams } from '../../utils/utilities';
import { setShowModal, setModalContent } from '../../features/utils/utils.slice';
import { getCurrentUser } from '../auth/auth.action';
import NetworkService from '../../utils/network.service';
import { setTokenChecked } from '../auth/auth.slice';
import { VerifiedNumberType } from '../../types/verified-number/verified-number.type';
import { VerifiedEmailType } from '../../types/verified-email/verified-email.type';

export const postUserDetails = createAsyncThunk<UserType, { data: unknown }, { state: RootState }>(
  'users/postUserDetails',
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post<UserType>(`${process.env.REACT_APP_API_URL}/users`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating user!');
    }
  }
);

export const postAgreementUserDetails = createAsyncThunk<
  UserType,
  { data: unknown; fetchCurrentUser?: boolean },
  { state: RootState }
>('users/postAgreementUserDetails', async ({ data, fetchCurrentUser }, thunkAPI) => {
  try {
    const response = await axios.post<UserType>(`${process.env.REACT_APP_API_URL}/agreements`, data);
    thunkAPI.dispatch(setModalContent(null));
    thunkAPI.dispatch(setShowModal(false));

    if (fetchCurrentUser) {
      thunkAPI.dispatch(getCurrentUser({ init: false }));
    }

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during accepting agreement!');
  }
});

export const getUsersList = createAsyncThunk<PaginationType<UserType>, RouteParamsType, { state: RootState }>(
  'users/getUsersList',
  async (params, thunkAPI) => {
    try {
      const additionalParams = additionalQueryParams(
        thunkAPI.getState().sharedReducer.selectedCompany,
        thunkAPI.getState().sharedReducer.selectedLocation,
        thunkAPI.getState().sharedReducer.includeSuborgs
      );
      const mergedParams = { ...params, ...additionalParams };
      const queryString = Object.keys(mergedParams)
        .map(key => key + '=' + mergedParams[key])
        .join('&');

      const response = await axios.get<PaginationType<UserType>>(
        `${process.env.REACT_APP_API_URL}/users${queryString ? `?${queryString}` : ''}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching users!');
    }
  }
);

export const getUserDetails = createAsyncThunk<UserType, { id: number }, { state: RootState }>(
  'users/getUserDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get<UserType>(`${process.env.REACT_APP_API_URL}/users/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching user!');
    }
  }
);

export const patchUserDetails = createAsyncThunk<UserType, { id: number; data: unknown }, { state: RootState }>(
  'users/patchUserDetails',
  async ({ data, id }, thunkAPI) => {
    try {
      const response = await axios.patch<UserType>(`${process.env.REACT_APP_API_URL}/users/${id}`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during changing user!');
    }
  }
);

export const deleteUserDetails = createAsyncThunk<
  { success: boolean; fetchList: boolean },
  { id: number; fetchList: boolean },
  { state: RootState }
>('users/deleteUserDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<{ success: boolean }>(`${process.env.REACT_APP_API_URL}/users/${id}`);
    if (fetchList) thunkAPI.dispatch(getUsersList(thunkAPI.getState().usersReducer.usersRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return { ...response.data, fetchList };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting user!');
  }
});

export const deleteAgreementUserDetails = createAsyncThunk<
  { success: boolean; fetchList: boolean },
  { userId: number; version: string; type: string; fetchList: boolean },
  { state: RootState }
>('users/deleteAgreementUserDetails', async ({ userId, type, version, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}/agreements?user_id=${userId}&version=${version}&type=${type}`);
    if (fetchList) thunkAPI.dispatch(getUsersList(thunkAPI.getState().usersReducer.usersRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return { ...response.data, fetchList };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting agreement!');
  }
});

export const patchUserPasswordAsSuperAdmin = createAsyncThunk<
  UserType,
  { id: number; data: unknown },
  { state: RootState }
>('users/patchUserPasswordAsSuperAdmin', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<UserType>(`${process.env.REACT_APP_API_URL}/users/${id}/changePassword`, data);
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during changing user password!');
  }
});

export const patchUserPassword = createAsyncThunk<UserType, { id: number; data: unknown }, { state: RootState }>(
  'users/patchUserPassword',
  async ({ data, id }, thunkAPI) => {
    try {
      const response = await axios.patch<UserType>(`${process.env.REACT_APP_API_URL}/users/changePassword`, data);
      thunkAPI.dispatch(setShowModal(false));
      thunkAPI.dispatch(setModalContent(null));
      thunkAPI.dispatch(getUserDetails({ id }));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during changing password!');
    }
  }
);

export const getUserRoles = createAsyncThunk<
  Array<{ id: string; name: string }>,
  { user_id?: number; org_id: number },
  { state: RootState }
>('users/getUserRoles', async (params, thunkAPI) => {
  try {
    if (!params.user_id) delete params.user_id;

    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    const response = await axios.get<Array<{ id: string; name: string }>>(
      `${process.env.REACT_APP_API_URL}/users/roles${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting roles list!');
  }
});

export const getAPICredentialsDetails = createAsyncThunk<unknown, { id: number; orgId: number }, { state: RootState }>(
  'users/getAPICredentialsDetails',
  async ({ id, orgId }, thunkAPI) => {
    try {
      const response = await axios.get<unknown>(`${process.env.REACT_APP_API_URL}/api-credentials/${orgId}/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting API Credentials!');
    }
  }
);

export const postAPICredentialsDetails = createAsyncThunk<
  unknown,
  { data: { organizationID: number; createdByID: number } },
  { state: RootState }
>('users/postAPICredentialsDetails', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<unknown>(`${process.env.REACT_APP_API_URL}/api-credentials`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating API Credentials!');
  }
});

export const patchAPICredentialsDetails = createAsyncThunk<
  unknown,
  { id: number; orgId: number },
  { state: RootState }
>('users/patchAPICredentialsDetails', async ({ orgId, id }, thunkAPI) => {
  try {
    const response = await axios.patch<unknown>(`${process.env.REACT_APP_API_URL}/api-credentials/${orgId}/${id}`, {});
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating API Credentials!');
  }
});

export const deleteAPICredentialsDetails = createAsyncThunk<
  { success: boolean; fetchList: boolean },
  { orgId: number; id: number; fetchList: boolean },
  { state: RootState }
>('users/deleteAPICredentialsDetails', async ({ orgId, id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}/api-credentials/${orgId}/${id}`);
    if (fetchList) thunkAPI.dispatch(getUsersList(thunkAPI.getState().usersReducer.usersRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return { ...response.data, fetchList };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting API Credentials!');
  }
});

export const getUserAccessList = createAsyncThunk<
  Array<{ id: string; name: string }>,
  { user_id: number },
  { state: RootState }
>('users/getUserAccessList', async (params, thunkAPI) => {
  try {
    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    const response = await axios.get<Array<{ id: string; name: string }>>(
      `${process.env.REACT_APP_API_URL}/user-access${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting user access list!');
  }
});

export const postUserAccess = createAsyncThunk<
  unknown,
  { data: { user: { id: number }; organization: { id: number } } },
  { state: RootState }
>('users/postUserAccess', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<unknown>(`${process.env.REACT_APP_API_URL}/user-access`, data);
    thunkAPI.dispatch(getUserAccessList({ user_id: thunkAPI.getState().usersReducer.userDetails.id }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating new access!');
  }
});

export const postSwitchUserOrganization = createAsyncThunk<
  unknown,
  { data: { organization: { id: number } } },
  { state: RootState }
>('users/postSwitchUserOrganization', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<{
      access_token: string;
    }>(`${process.env.REACT_APP_API_URL}/user-access/switchToOrganization`, data);

    localStorage.setItem('token', response.data.access_token);
    NetworkService.setupDefaultHeaders(response.data.access_token);
    thunkAPI.dispatch(setTokenChecked(true));
    thunkAPI.dispatch(getCurrentUser({ init: false }));
    thunkAPI.dispatch(setModalContent(null));
    thunkAPI.dispatch(setShowModal(false));

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during getting organization list!');
  }
});

export const deleteUserAccessDetails = createAsyncThunk<
  { success: boolean; fetchList: boolean },
  { id: number; fetchList: boolean },
  { state: RootState }
>('users/deleteUserAccessDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}/user-access/${id}`);
    if (fetchList) thunkAPI.dispatch(getUserAccessList({ user_id: thunkAPI.getState().usersReducer.userDetails.id }));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return { ...response.data, fetchList };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during removing user access!');
  }
});

export const postVerifiedNumber = createAsyncThunk<
  VerifiedNumberType,
  { data: { number: string; number_country_code: string; number_dial_code: string } },
  { state: RootState }
>('users/postVerifiedNumber', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<VerifiedNumberType>(`${process.env.REACT_APP_API_URL}/verification/number`, data);

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating Number!');
  }
});

export const postVerifiedEmail = createAsyncThunk<VerifiedEmailType, { data: { email: string } }, { state: RootState }>(
  'users/postVerifiedEmail',
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post<VerifiedEmailType>(`${process.env.REACT_APP_API_URL}/verification/email`, data);
      thunkAPI.dispatch(setShowModal(false));
      thunkAPI.dispatch(setModalContent(null));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating Email!');
    }
  }
);

export const deleteVerifiedEmailDetails = createAsyncThunk<{ success: boolean }, { id: number }, { state: RootState }>(
  'users/deleteVerifiedEmailDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.delete<{
        success: boolean;
      }>(`${process.env.REACT_APP_API_URL}/verification/email/${id}`);
      thunkAPI.dispatch(setShowModal(false));
      thunkAPI.dispatch(setModalContent(null));
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during removing Email!');
    }
  }
);

export const deleteVerifiedNumberDetails = createAsyncThunk<{ success: boolean }, { id: number }, { state: RootState }>(
  'users/deleteVerifiedNumberDetails',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.delete<{
        success: boolean;
      }>(`${process.env.REACT_APP_API_URL}/verification/number/${id}`);
      thunkAPI.dispatch(setShowModal(false));
      thunkAPI.dispatch(setModalContent(null));
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during removing Number!');
    }
  }
);

export const postVerifyVerifiedEmail = createAsyncThunk<{ success: boolean }, { data: unknown }, { state: RootState }>(
  'users/postVerifyVerifiedEmail',
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post<{
        success: boolean;
      }>(`${process.env.REACT_APP_API_URL}/verification/verify/email/`, data);
      thunkAPI.dispatch(setShowModal(false));
      thunkAPI.dispatch(setModalContent(null));
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during verifying Email!');
    }
  }
);

export const postVerifyVerifiedNumber = createAsyncThunk<
  { success: boolean },
  { data: { id: number; code: string } },
  {
    state: RootState;
  }
>('users/postVerifyVerifiedNumber', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}/verification/verify/number/`, data);
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return { ...response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during verifying Number!');
  }
});

export const postResendVerifiedEmail = createAsyncThunk<
  { success: boolean },
  { data: unknown; id: number },
  { state: RootState }
>('users/postResendVerifiedEmail', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.post<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}/verification/resend/email/${id}`, data);
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return { ...response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during sending Email!');
  }
});

export const postResendVerifiedNumber = createAsyncThunk<
  { success: boolean },
  { data: unknown; id: number },
  { state: RootState }
>('users/postResendVerifiedNumber', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.post<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}/verification/resend/number/${id}`, data);

    return { ...response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during sending code!');
  }
});
