import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { additionalQueryParams } from '../../utils/utilities';
import { setShowModal, setModalContent } from '../../features/utils/utils.slice';
import {
  CreateEquipmentGroupDto,
  EquipmentGroupDetailsType,
  EquipmentGroupListItemType,
  UpdateEquipmentGroupDto,
} from '../../types/equipment-group/equipment-group.type';
import { EquipmentGroupTypeListItemType } from '../../types/equipment-group/equipment-group-type/equipment-group-type.type';
import { EQUIPMENT_GROUP_PREVIEW_HASH_TYPE } from '../../utils/constants';

export const postEquipmentGroupDetails = createAsyncThunk<
  EquipmentGroupDetailsType,
  { data: CreateEquipmentGroupDto },
  { state: RootState }
>('equipmentGroups/postEquipmentGroupDetails', async ({ data }, thunkAPI) => {
  try {
    const response = await axios.post<EquipmentGroupDetailsType>(
      `${process.env.REACT_APP_API_URL}/equipment-group`,
      data
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during creating equipment group!');
  }
});

export const getEquipmentGroupsList = createAsyncThunk<
  PaginationType<EquipmentGroupListItemType>,
  RouteParamsType,
  { state: RootState }
>('equipmentGroups/getEquipmentGroupsList', async (params, thunkAPI) => {
  try {
    const additionalParams = additionalQueryParams(
      thunkAPI.getState().sharedReducer.selectedCompany,
      thunkAPI.getState().sharedReducer.selectedLocation,
      thunkAPI.getState().sharedReducer.includeSuborgs
    );
    const mergedParams = { ...params, ...additionalParams };
    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<EquipmentGroupListItemType>>(
      `${process.env.REACT_APP_API_URL}/equipment-group${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching equipment groups!');
  }
});

export const getEquipmentGroupTypesList = createAsyncThunk<
  Array<EquipmentGroupTypeListItemType>,
  void,
  { state: RootState }
>('equipmentGroups/getEquipmentGroupTypesList', async (params, thunkAPI) => {
  try {
    const response = await axios.get<Array<EquipmentGroupTypeListItemType>>(
      `${process.env.REACT_APP_API_URL}/equipment-group/types`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching equipment group types!');
  }
});

export const getEquipmentGroupDetails = createAsyncThunk<
  EquipmentGroupDetailsType,
  { id: number },
  {
    state: RootState;
  }
>('equipmentGroups/getEquipmentGroupDetails', async ({ id }, thunkAPI) => {
  try {
    const response = await axios.get<EquipmentGroupDetailsType>(
      `${process.env.REACT_APP_API_URL}/equipment-group/${id}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching equipment group!');
  }
});

export const patchEquipmentGroupDetails = createAsyncThunk<
  EquipmentGroupDetailsType,
  { id: number; data: UpdateEquipmentGroupDto },
  { state: RootState }
>('equipmentGroups/patchEquipmentGroupDetails', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<EquipmentGroupDetailsType>(
      `${process.env.REACT_APP_API_URL}/equipment-group/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating equipment group!');
  }
});

export const deleteEquipmentGroupDetails = createAsyncThunk<
  EquipmentGroupDetailsType,
  { id: number; fetchList: boolean },
  { state: RootState }
>('equipmentGroups/deleteEquipmentGroupDetails', async ({ id, fetchList }, thunkAPI) => {
  try {
    const response = await axios.delete<EquipmentGroupDetailsType>(
      `${process.env.REACT_APP_API_URL}/equipment-group/${id}`
    );
    if (fetchList)
      thunkAPI.dispatch(getEquipmentGroupsList(thunkAPI.getState().equipmentGroupsReducer.equipmentGroupsRouteParams));
    thunkAPI.dispatch(setShowModal(false));
    thunkAPI.dispatch(setModalContent(null));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting equipment group!');
  }
});

export const getPublicEquipmentGroupsDetails = createAsyncThunk<
  EquipmentGroupDetailsType,
  { hash: string; type: string },
  { state: RootState }
>('equipmentGroups/getPublicEquipmentGroupsDetails', async ({ hash, type }, thunkAPI) => {
  try {
    let url;
    Object.values(EQUIPMENT_GROUP_PREVIEW_HASH_TYPE).forEach(key => {
      if (type === key) {
        url = `${process.env.REACT_APP_API_URL}/${key}/public/${hash}`;
        if (key === 'router') url = `${process.env.REACT_APP_API_URL}/routers/public/${hash}`;
      }
    });

    const response = await axios.get<EquipmentGroupDetailsType>(url);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching equipment group!');
  }
});
