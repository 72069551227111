import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { createSlice } from '@reduxjs/toolkit';
import { OrderType } from '../../types/order/order.type';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import snackbarUtils from '../../utils/SnackbarUtils';
import {
  deleteOrderDetails,
  deleteOrderFile,
  getOrderDetails,
  getOrdersList,
  patchOrderDetails,
  postOrderDetails,
  postOrdersSimActivationEmail,
  postOrdersTrackingInfoEmail,
} from './orders.action';

export interface OrdersState {
  ordersList: null | PaginationType<OrderType>;
  ordersListFetchStatus: FETCH_STATUS;
  ordersRouteParams: RouteParamsType;
  orderDetails: null | OrderType;
  orderDetailsFetchStatus: FETCH_STATUS;
  orderDetailsPostStatus: FETCH_STATUS;
  orderDetailsPatchStatus: FETCH_STATUS;
  orderDetailsDeleteStatus: FETCH_STATUS;
  orderSimActivationDetailsPostStatus: FETCH_STATUS;
  orderTrackingInfoDetailsPostStatus: FETCH_STATUS;
  orderFileDetailsDeleteStatus: FETCH_STATUS;
}

export const initialOrdersRouteParams = {
  page: 1,
  limit: 10,
  order: '-orders.id',
  q: '',
  status: '',
};

const INIT_STATE: OrdersState = {
  ordersRouteParams: initialOrdersRouteParams,
  ordersList: null,
  ordersListFetchStatus: FETCH_STATUS.NULL,
  orderDetails: null,
  orderDetailsFetchStatus: FETCH_STATUS.NULL,
  orderDetailsPostStatus: FETCH_STATUS.NULL,
  orderDetailsPatchStatus: FETCH_STATUS.NULL,
  orderDetailsDeleteStatus: FETCH_STATUS.NULL,
  orderFileDetailsDeleteStatus: FETCH_STATUS.NULL,
  orderTrackingInfoDetailsPostStatus: FETCH_STATUS.NULL,
  orderSimActivationDetailsPostStatus: FETCH_STATUS.NULL,
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState: INIT_STATE,
  reducers: {
    setOrdersList: (state, action) => {
      state.ordersList = action.payload;
    },
    setOrdersListFetchStatus: (state, action) => {
      state.ordersListFetchStatus = action.payload;
    },
    setOrderDetails: (state, action) => {
      state.orderDetails = action.payload;
    },
    setOrderDetailsFetchStatus: (state, action) => {
      state.orderDetailsFetchStatus = action.payload;
    },
    setOrderDetailsPostStatus: (state, action) => {
      state.orderDetailsPostStatus = action.payload;
    },
    setOrderDetailsPatchStatus: (state, action) => {
      state.orderDetailsPatchStatus = action.payload;
    },
    setOrderDetailsDeleteStatus: (state, action) => {
      state.orderDetailsDeleteStatus = action.payload;
    },
    setOrderFileDetailsDeleteStatus: (state, action) => {
      state.orderFileDetailsDeleteStatus = action.payload;
    },
    setOrderTrackingInfoDetailsPostStatus: (state, action) => {
      state.orderTrackingInfoDetailsPostStatus = action.payload;
    },
    setOrderSimActivationDetailsPostStatus: (state, action) => {
      state.orderSimActivationDetailsPostStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'orders') {
        state.ordersRouteParams = { ...state.ordersRouteParams, ...payload.data };
      }
    });

    // CREATE ORDERS DETAILS
    builder.addCase(postOrderDetails.pending, state => {
      state.orderDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postOrderDetails.rejected, (state, action) => {
      state.orderDetailsPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postOrderDetails.fulfilled, state => {
      state.orderDetailsPostStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Order created successfully!');
    });

    // GET ORDERS LIST
    builder.addCase(getOrdersList.pending, state => {
      state.ordersListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getOrdersList.rejected, (state, action) => {
      state.ordersListFetchStatus = FETCH_STATUS.REJECTED;
      state.ordersList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getOrdersList.fulfilled, (state, action) => {
      state.ordersListFetchStatus = FETCH_STATUS.FULFILLED;
      state.ordersList = action.payload;
    });

    // GET ORDERS DETAILS
    builder.addCase(getOrderDetails.pending, state => {
      state.orderDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getOrderDetails.rejected, (state, action) => {
      state.orderDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.orderDetails = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getOrderDetails.fulfilled, (state, action) => {
      state.orderDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.orderDetails = action.payload;
    });

    // PATCH ORDERS DETAILS
    builder.addCase(patchOrderDetails.pending, state => {
      state.orderDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchOrderDetails.rejected, (state, action) => {
      state.orderDetailsPatchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(patchOrderDetails.fulfilled, state => {
      state.orderDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Order updated successfully!');
    });

    // DELETE ORDERS DETAILS
    builder.addCase(deleteOrderDetails.pending, state => {
      state.orderDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteOrderDetails.rejected, (state, action) => {
      state.orderDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(deleteOrderDetails.fulfilled, state => {
      state.orderDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Order deleted successfully!');
    });

    // DELETE ORDER FILE DETAILS
    builder.addCase(deleteOrderFile.pending, state => {
      state.orderFileDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteOrderFile.rejected, (state, action) => {
      state.orderFileDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(deleteOrderFile.fulfilled, state => {
      state.orderFileDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Order file deleted successfully!');
    });

    // POST SIM ORDERS DETAILS
    builder.addCase(postOrdersSimActivationEmail.pending, state => {
      state.orderSimActivationDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postOrdersSimActivationEmail.rejected, (state, action) => {
      state.orderSimActivationDetailsPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postOrdersSimActivationEmail.fulfilled, state => {
      state.orderSimActivationDetailsPostStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('SIM activation email has been sent!');
    });

    // POST TRACKING ORDERS DETAILS
    builder.addCase(postOrdersTrackingInfoEmail.pending, state => {
      state.orderTrackingInfoDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postOrdersTrackingInfoEmail.rejected, (state, action) => {
      state.orderTrackingInfoDetailsPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postOrdersTrackingInfoEmail.fulfilled, state => {
      state.orderTrackingInfoDetailsPostStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Tracking info email has been sent!');
    });
  },
});

export const {
  setOrderDetailsDeleteStatus,
  setOrdersListFetchStatus,
  setOrderDetailsFetchStatus,
  setOrderDetailsPatchStatus,
  setOrderDetailsPostStatus,
  setOrdersList,
  setOrderDetails,
  setOrderSimActivationDetailsPostStatus,
  setOrderTrackingInfoDetailsPostStatus,
  setOrderFileDetailsDeleteStatus,
} = ordersSlice.actions;

export default ordersSlice.reducer;
