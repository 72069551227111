import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserType } from '../../types/user/user.type';
import { RootState } from '../../redux/Store';
import { history } from '../../utils/utilities';
import {
  setIncludeSuborgs,
  setSelectedCompany,
  setSelectedLocation,
  setSelectedUserAccess,
} from '../shared/shared.slice';

export const getCurrentUser = createAsyncThunk<
  { user: UserType; init: boolean },
  { init: boolean },
  {
    state: RootState;
  }
>('auth/getCurrentUser', async (params, thunkAPI) => {
  try {
    const response = await axios.get<UserType>(`${process.env.REACT_APP_API_URL}/users/profile`);
    return { user: response.data, init: params.init };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching user profile!');
  }
});

export const postLogin = createAsyncThunk<
  { user: UserType; access_token: string },
  { password: string; username: string },
  { state: RootState }
>('auth/postLogin', async (data, thunkAPI) => {
  try {
    const response = await axios.post<{
      user: UserType;
      access_token: string;
    }>(`${process.env.REACT_APP_API_URL}/auth/login`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during login!');
  }
});

export const postForgotPassword = createAsyncThunk<
  { success: boolean },
  { email: string; id: number },
  { state: RootState }
>('auth/postForgotPassword', async (data, thunkAPI) => {
  try {
    const response = await axios.post<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}/users/forgotPassword/`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during forgot password!');
  }
});

export const postResetPassword = createAsyncThunk<
  { success: boolean },
  { password: string; token: string },
  { state: RootState }
>('auth/postResetPassword', async (data, thunkAPI) => {
  try {
    const response = await axios.post<{
      success: boolean;
    }>(`${process.env.REACT_APP_API_URL}/users/restorePassword`, data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during reset password!');
  }
});

export const logout = createAsyncThunk<void, void, { state: RootState }>('auth/logout', async (_, thunkAPI) => {
  try {
    history.push('/authentication/login');
    localStorage.removeItem('token');
    thunkAPI.dispatch(setSelectedCompany(null));
    thunkAPI.dispatch(setSelectedLocation(null));
    thunkAPI.dispatch(setIncludeSuborgs(false));
    thunkAPI.dispatch(setSelectedUserAccess(null));

    delete axios.defaults.headers.common['Authorization'];
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during reset password!');
  }
});
